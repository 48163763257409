.staff-grid {
    display: grid;
    flex-grow: 1;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
    margin-right: 24px;

    &__item {
        color: inherit !important;
        cursor: pointer;
        outline: none;
        min-height: 130px;
        display: flex;
        flex-direction: column;
        padding: 12px 16px;
        gap: 16px;
        border-radius: 16px;
        background: var(--Palette-Absolute-White, #FFF);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);

        &:hover,
        &:focus {
            .staff-grid__item-name::after {
                opacity: 1;
            }
        }
    }

    .employee-header {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex-grow: 1;

        .staff-grid__item-name {
            @include text-body-medium;
            padding-right: 40px;
            position: relative;

            &::after {
                @include svg-bg-icon(pencil, $info-400);
                content: '';
                background-color: $info-100;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 6px;
                display: block;
                height: 28px;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: 32px;
            }

            &:hover::after {
                @include svg-bg-icon(pencil, $info-500);
                background-color: $info-200;
            }
        }
    }
    .contact-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-grow: 1;
    }
}
