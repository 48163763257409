//
// Form Check
//
.form-check {
  display: flex !important;
  padding-left: initial;

  &:hover {
    .form-check-input:not(:disabled) {
      box-shadow: inset 0 0 0 1px $neutral-300;

      &:checked {
        &::after {
          background-color: $primary-500;
        }
      }
    }
  }

  &.form-check-inline {
    display: inline-flex !important;
  }

  &.flex-column {
    flex-direction: row !important;
  }

  .form-check-label {
    @include text-body-regular;
    cursor: pointer;
  }

  @mixin colored-check($color-without-hash-sign) {
    content: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.03033 8.47748C2.73744 8.18459 2.26256 8.18459 1.96967 8.47748C1.67678 8.77038 1.67678 9.24525 1.96967 9.53814L3.03033 8.47748ZM6 12.5078L5.46967 13.0381C5.61607 13.1845 5.81647 13.2639 6.0234 13.2574C6.23034 13.251 6.4254 13.1593 6.56238 13.004L6 12.5078ZM14.0624 4.50403C14.3364 4.19344 14.3068 3.71949 13.9962 3.44543C13.6856 3.17138 13.2117 3.201 12.9376 3.5116L14.0624 4.50403ZM1.96967 9.53814L5.46967 13.0381L6.53033 11.9775L3.03033 8.47748L1.96967 9.53814ZM6.56238 13.004L14.0624 4.50403L12.9376 3.5116L5.43762 12.0116L6.56238 13.004Z' fill='%23#{$color-without-hash-sign}'/%3E%3C/svg%3E%0A");
  }

  @mixin colored-dash($color-without-hash-sign) {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3' y='7.00781' width='10' height='2' rx='1' fill='%23#{$color-without-hash-sign}'/%3E%3C/svg%3E%0A");
  }

  @mixin colored-bullet($color-without-hash-sign) {
    content: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='5' y='5.00781' width='6' height='6' rx='3' fill='%23#{$color-without-hash-sign}'/%3E%3C/svg%3E%0A");
  }

  .form-check-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    flex-shrink: 0;
    float: none;
    margin-top: initial;
    margin-left: initial;
    margin-right: 8px;
    position: relative;
    z-index: 1;

    &:focus {
      box-shadow: inset 0 0 0 2px $primary-400;

      &:checked {
        &::after {
          box-shadow: inset 0 0 0 2px $primary-500;
        }
      }
    }

    &:indeterminate:not([type=radio]) {
      @include colored-dash('000000');
      background-position: center 2px;
    }

    &:disabled {
      background-color: $neutral-100;
      cursor: not-allowed;

      &:checked {
        &[type=radio]::before {
          @include colored-bullet('B6B7B9');
        }

        &::before {
          @include colored-check('B6B7B9');
        }

        &::after {
          background-color: $neutral-100;
        }
      }

      & + .form-check-label {
        color: $neutral-300;
        cursor: not-allowed;
      }

      &:indeterminate:not([type=radio]) {
        @include colored-dash('B6B7B9');
      }
    }

    &:checked {
      background-color: transparent;
      background-image: none;
      border-color: transparent;

      &::before,
      &::after {
        transform: scale(1);
      }

      &::before {
        transition: transform .15s ease-in-out .15s;
      }

      &::after {
        border-radius: inherit;
        opacity: 1;
        transition: .15s ease-in;
      }
    }

    &::before,
    &::after {
      transform: scale(0);
    }

    &[type=radio]::before {
      @include colored-bullet('000000');
    }

    // check
    &::before {
      @include colored-check('000000');
      display: block;
      height: 17px;
      left: 2px;
      position: relative;
      top: 2px;
      transition: transform .15s ease-in-out;
      width: 17px;
      z-index: 2;
    }

    // background
    &::after {
      content: '';
      background-color: $primary-300;
      border-radius: 50%;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity .15s ease-in;
      width: 100%;
      z-index: 1;
    }
  }

  &.form-check-custom {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: 0;

    .form-check-input {
      margin: 0;
      float: none;
      flex-shrink: 0;
    }

    .form-check-label {
      margin-left: 8px;
    }
  }

  &:not(.form-switch) {
    .form-check-input {
      &[type="checkbox"] {
        background-size: $form-check-input-bg-size;
      }
    }
  }

  &.form-check-solid {
    .form-check-input {
      border: 0;
      background-color: $form-check-input-bg-solid;

      &:active,
      &:focus {
        filter: none;
        background-color: $form-check-input-bg-solid;
      }

      &:checked {
        background-color: $form-check-input-checked-bg-color;
      }
    }
  }

  // Danger state
  &.form-check-danger {
    .form-check-input:not(:disabled) {
      box-shadow: inset 0 0 0 2px $danger-300;

      &:checked {
        &[type=radio]::before {
          @include colored-bullet('900632');
        }

        &::before {
          @include colored-check('900632'); // danger-600
        }

        &::after {
          background-color: $danger-200;
          box-shadow: inset 0 0 0 2px $danger-300;
        }
      }

      &:indeterminate:not([type=radio]) {
        @include colored-dash('900632');
      }
    }
  }
}

// Sizing
.form-check.form-check-custom {
  &.form-check-sm {
    .form-check-input {
      height: $form-check-input-width-sm;
      width: $form-check-input-width-sm;
    }
  }

  &.form-check-lg {
    .form-check-input {
      height: $form-check-input-width-lg;
      width: $form-check-input-width-lg;
    }
  }
}

// Form switch
.form-switch.form-check-solid {
  .form-check-input {
    height: $form-switch-height;
    background-image: escape-svg($form-switch-bg-image-solid);
    border-radius: $form-switch-border-radius;

    &:checked {
      filter: none;
      background-image: escape-svg($form-switch-checked-bg-image);
    }
  }

  &.form-switch-sm {
    .form-check-input {
      height: $form-switch-height-sm;
      width: $form-switch-width-sm;
    }
  }

  &.form-switch-lg {
    .form-check-input {
      height: $form-switch-height-lg;
      width: $form-switch-width-lg;
    }
  }
}
