.delivery-index-page {
  &__left-column {
    &__card {
      &__delivery-type {
        margin: 16px 0px 0px;
        p{
          display: flex;
          flex-direction: column;
          align-self: stretch;
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
        }
      }

      &__title {
        color: black;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;

        display: flex;
        align-items: center;
        gap: 8px;
      }

      &__recipient {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        p {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 0px;
        }

        &__contacts {
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
        }
      }

    }
  }
}

.select2-results__options {
  margin: 0px 5px 5px 5px !important;
}

.select2-results__option.loading-results { color: grey !important;}