.persons-list {
  &__items {
    &:not(:last-child):after {
      margin-top: 16px;

      content: '';
      display: block;
      width: 100%;
      border-top: 1px solid $gray-200;
    }
  }
}