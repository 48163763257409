//
// Select2
//
.select2-container .select2-selection--single {
  background-color: $input-bg !important;
  border: none !important;
  border-radius: $input-border-radius !important;
  box-shadow: none !important;
  color: $body-color !important;
  height: initial !important;
  padding: 0 !important;
  transition-delay: 0s;

  &:hover {
    box-shadow: inset 0 0 0 1px $neutral-300;
    transition-duration: 0;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px $primary-400;
  }

  .select2-selection__rendered {
    min-height: 40px !important;
    padding-top: 8px !important;
    padding-left: 12px !important;
    padding-right: 32px !important;
    padding-bottom: 8px !important;
  }
}

.select2-container .select2-selection--multiple {
  background-color: $input-bg !important;

  & .select2-selection__clear {
    font-size: 1.5em !important;
  }
}

.select2-container--krajee-bs4 .select2-selection--multiple {
  box-shadow: none !important;
  border: none !important;

  & .select2-selection__choice__remove {
    font-size: 1.5rem !important;
    padding: 2px 0 0 0.2rem !important;
  }

  & .select2-selection__choice__remove:hover {
    color: red !important;
  }

  & .select2-selection__clear {
    margin-top: 0.35rem !important;
  }
}

.select2-container--krajee-bs4 .select2-selection--single {
  .select2-selection__placeholder {
    @include text-body-regular;
    color: $neutral-300;
  }

  .select2-selection__arrow {
    border-left: none !important;
    height: 100% !important;
    right: 0 !important;
    top: 0 !important;
    width: 32px !important;

    b {
      @include svg-bg-icon(angleDown, $neutral-400);
      background-position: center calc(50% + 1px) !important;
      background-repeat: no-repeat !important;
      border: none !important;
      height: 100% !important;
      left: 0 !important;
      margin-top: initial !important;
      margin-left: initial !important;
      top: 0 !important;
      width: 100% !important;
    }
  }

  .select2-selection__rendered {
    color: $body-color;
  }

  &.select2-selection--clearable {
    display: flex;

    .select2-selection__clear {
      @include svg-bg-icon(cross, $neutral-400);
      background-position: center calc(50% + 1px);
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: 32px;
      order: 2;
      width: 24px;

      &:hover,
      &:focus {
        @include svg-bg-icon(cross, $danger-400);
      }

      span {
        display: none;
      }
    }

    .select2-selection__rendered {
      order: 1;
      padding-right: 8px !important;
    }
  }
}

.select2-container--krajee-bs4 {
  & .select2-selection {
    box-shadow: none;
    background-color: #f6f6f6;
    border-radius: 8px;
    border: 0;
    color: #f6f6f6;
    outline: 0;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  & .select2-selection__choice__display {
    color: #000;
    font-family: 'Beeline Sans', sans-serif;
  }

  & .select2-selection--multiple {
    &.select2-search--inline {
      display: none;
    }

    & .select2-selection__choice {
      color: #595A5F;
      background: #e1e2e6;
      border: 1px solid #e1e2e6;
      border-radius: 4px;
      width: auto;
      display: inline-block;
      margin: 0.35rem 0.25rem 0 0.25rem;
      padding: 0.15rem 0.05rem 0.15rem 0.25rem;
      font-size: 1rem;
      line-height: 1.3rem;
    }

    & .select2-selection__choice__remove {
      display: block;
      width: 16px;
      height: 16px;
      margin-right: 0.25rem;

      & span {
        visibility: hidden;
      }

      & span:before {
        @include svg-bg-icon(cross, #595A5F);
        $size: 16px;
        content: '';
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        height: $size;
        width: $size;
        vertical-align: middle;
        visibility: visible;
      }
    }

    & .select2-selection__choice:last-child {
      margin-bottom: 0.50rem;
    }
  }

  &.select2-container--open:not(.select2-container--disabled) .select2-selection,
  &:not(.select2-container--disabled) .select2-selection:focus {
    box-shadow: none;
  }

  &.select2-container--open.select2-container--below .select2-selection {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &:not(.select2-container--disabled) .select2-dropdown {
    border: none;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    margin-top: initial;
    padding: 4px;
  }

  .select2-results__option[aria-selected] {
    @include text-body-regular;
    background-color: transparent;
    border-radius: 6px;
    color: $body-color;
    padding: 10px 16px 10px 32px;
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $neutral-100;
    color: $body-color;
  }

  .select2-results__option--selected {
    @include svg-bg-icon(check, $success-400);
    background-position: 8px center;
    background-repeat: no-repeat;
  }

  .select2-search--dropdown .select2-search__field {
    @extend .form-control;
    @include svg-bg-icon(search, $neutral-400);
    background-position: 8px 12px;
    background-repeat: no-repeat;
    padding-left: 28px;
  }

  li {
    &.select2-results__option {
      background-color: inherit;
      color: #87888c;
    }
  }
}

.select2-search--dropdown {
  margin-bottom: 4px;
  padding: 0;
}

.has-success .select2-container--open .select2-selection, .has-success .select2-container--krajee-bs4.select2-container--focus .select2-selection {
  box-shadow: none;
}

.has-error {
  .help-block {
    @extend .invalid-feedback;
  }
}
//Добавим стрелочку для всех селектов с типом multiple
.select2-selection--multiple > .select2-selection__clear {
  position: absolute;
  right: 20px;
}

.select2-selection--multiple:before {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width= '16' height= '17' fill= 'none' xmlns= 'http://www.w3.org/2000/svg' %3E%3Cpath d= 'M2.5 5.508l5.332 4.847a.25.25 0 00.336 0L13.5 5.508' stroke= '%237C7D81' stroke-width= '1.5' stroke-linecap= 'round' /%3E%3C/svg%3E");
  background-position: 50% calc(50% + 1px) !important;
  background-repeat: no-repeat !important;
  border: none !important;
  height: 14px !important;
  right: 10px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  top: 10px !important;
  width: 14px !important;
}

// TODO: style focus state for keyboard navigation
