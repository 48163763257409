.brands-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 8px;
  padding: 8px;

  &__filter {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    display: inline-block;
    width: 224px;
    overflow: hidden;
    position: relative;
    .filter-dropdown__search {
      padding: 8px;
    }
  }

  &__item_message {
    display: flex;
    align-items: flex-start;
    padding: 8px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 8px;
    cursor: pointer;

    &_current {
      background: $gray-100;
      border-radius: 8px;
    }
  }

  &__item-name {
    @include text-body-regular;
    color: black;
    transition: color 0.2s ease-in-out;
  }

  &__item-marker {
    margin: 6px 12px;
    width: 8px;
    height: 8px;
    background: $gray-300;
    border-radius: 8px;
    flex-shrink: 0;
  }
}
