//
// SVG Icon
//

.svg-icon {
  line-height: 1;

  @include svg-icon-size(get($font-sizes, 5));
  @include svg-icon-color($text-muted);

  // Theme colors
  @each $name, $color in $theme-text-colors {
    &.svg-icon-#{$name} {
      @include svg-icon-color($color);
    }
  }

  // Responsive icon sizes
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      // Sizes
      @each $name, $value in $font-sizes {
        &.svg-icon#{$infix}-#{$name} {
          @include svg-icon-size($value, true);
        }
      }
    }
  }
}

/**
  Иконки приложения - не меню
  Сама иконка добавляется в файле _svg-bg-icon.scss
  Тут описывается ее название и варианты цветов
  В коде используется так: svg-icon svg-icon-plus svg-icon-plus-blue
 */
@mixin svg-icon-container {
  $size: 16px;
  content: '';
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: $size;
  width: $size;
  vertical-align: middle;
}

.svg-icon-plus {
  @include svg-icon-container;

  &-blue {
    @include svg-bg-icon(plus, #007aff);
  }

  &-yellow {
    @include svg-bg-icon(plus, #ffcd69);
  }

  &-black {
    @include svg-bg-icon(plus, #000000);
  }
}

.svg-icon-info {
  @include svg-icon-container;

  &-grey {
    @include svg-bg-icon(info, $neutral-300);
  }
}

.svg-icon-refresh {
  @include svg-icon-container;

  &-blue {
    @include svg-bg-icon(refresh, $info-400);
  }
}

.svg-icon-sort-desc {
  @include svg-icon-container;

  &-main {
    @include svg-bg-icon(sort-desc, black);
  }
}

.svg-icon-magic-wand {
  @include svg-icon-container;

  &-main {
    width:20px;
    height: 20px;
    @include svg-bg-icon(magic-wand, white);
  }

  &-link {
    width:20px;
    height: 20px;
    @include svg-bg-icon(magic-wand, $link-color);
  }
}

.svg-icon-new-item {
  @include svg-icon-container;

  &-purple {
    @include svg-bg-icon(new-item, $accent-violet);
  }

  &-white {
    @include svg-bg-icon(new-item, white);
  }
}

.svg-icon-move-in {
    @include svg-icon-container;

    &-yellow {
        @include svg-bg-icon(move-in, #F5A207);
    }

    &-black {
        @include svg-bg-icon(move-in, #000);
    }
}
