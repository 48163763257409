//
// Form Label
//
label {
  margin-bottom: initial;
}

.form-label {
  display: block;
}

.input-label,
.select-label,
.dropdown-label,
.datepicker-label {
  padding-left: 12px;
  padding-right: 12px;
}
