.certificate-menu {
  display: flex;
  align-items: flex-start;
  padding: 0px 0px 80px;
  gap: 24px;
  flex: none;
  align-self: stretch;
  flex-grow: 1;
  .filter-dropdown {
    max-width: initial;
  }
}

.certificate-item {
  padding: 8px 24px 16px;
  gap: 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.certificate-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px 0px;
  gap: 16px;
  height: 308px;
  flex: none;
  align-self: stretch;
  flex-grow: 1;

  &__item {
    width: 329px;
    height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
}

.cert-model__column-content {
  padding: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  a {
    display: block;
  }
}

.certificate-header {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.view-certificates {
  padding-top: 16px;
}