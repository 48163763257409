.territory-item {
  @extend .card, .card-body;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.territory-item__header {
  display: flex;
  flex-direction: column;
}

.territory-item__hint {
  @include text-small-regular;
  color: $gray-500;
}