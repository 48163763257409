.preorder-detail-sales-control {
  @extend .card;

  margin-top: 24px;

  &__head {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    flex-grow: 1;
  }

  &__title-head {
    @include h4;
  }

  &__title-info {
    display: flex;
    @include text-small-regular;
  }

  &__left, &__right {
    flex-grow: 1;
    ul {
      list-style: none;
      margin: 0;
      padding: 7px;
    }

    ul li::before {
      content: "• ";
      color: #B6B7B9;
      font-size: 26px;
    }
  }

  &__left-head, &__right-head {
    font-weight: bold;
  }

  &__body {
    margin: 16px 24px;
    display: flex;
    gap: 24px;
  }
}