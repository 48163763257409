.delete-mask-item {
  display: flex;
  align-items: center;
  gap: 8px;

  &__name {
    flex-grow: 1;
  }

  &__delete-button {
    width: 32px;
    height: 32px;

    border-radius: 8px;
    cursor: pointer;

    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.75 4C2.33579 4 2 4.33579 2 4.75C2 5.16421 2.33579 5.5 2.75 5.5H3.5V11.5C3.5 12.8807 4.61929 14 6 14H10C11.3807 14 12.5 12.8807 12.5 11.5V5.5H13.25C13.6642 5.5 14 5.16421 14 4.75C14 4.33579 13.6642 4 13.25 4H2.75ZM6.75 7C6.47386 7 6.25 7.22386 6.25 7.5V10.5C6.25 10.7761 6.47386 11 6.75 11C7.02614 11 7.25 10.7761 7.25 10.5V7.5C7.25 7.22386 7.02614 7 6.75 7ZM9.25 7C8.97386 7 8.75 7.22386 8.75 7.5V10.5C8.75 10.7761 8.97386 11 9.25 11C9.52614 11 9.75 10.7761 9.75 10.5V7.5C9.75 7.22386 9.52614 7 9.25 7Z' fill='%23B6B7B9'/%3E%3Cpath d='M5.30902 3.38197C5.7325 2.53501 6 2 7.54508 2H8.45492C10 2 10.2675 2.53501 10.691 3.38197L11 4H5L5.30902 3.38197Z' fill='%23B6B7B9'/%3E%3C/svg%3E%0A");
  }

  &__delete-button:hover {
    background: var(--bs-gray-100);
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.75 4C2.33579 4 2 4.33579 2 4.75C2 5.16421 2.33579 5.5 2.75 5.5H3.5V11.5C3.5 12.8807 4.61929 14 6 14H10C11.3807 14 12.5 12.8807 12.5 11.5V5.5H13.25C13.6642 5.5 14 5.16421 14 4.75C14 4.33579 13.6642 4 13.25 4H2.75ZM6.75 7C6.47386 7 6.25 7.22386 6.25 7.5V10.5C6.25 10.7761 6.47386 11 6.75 11C7.02614 11 7.25 10.7761 7.25 10.5V7.5C7.25 7.22386 7.02614 7 6.75 7ZM9.25 7C8.97386 7 8.75 7.22386 8.75 7.5V10.5C8.75 10.7761 8.97386 11 9.25 11C9.52614 11 9.75 10.7761 9.75 10.5V7.5C9.75 7.22386 9.52614 7 9.25 7Z' fill='%23E60A3C'/%3E%3Cpath d='M5.30902 3.38197C5.7325 2.53501 6 2 7.54508 2H8.45492C10 2 10.2675 2.53501 10.691 3.38197L11 4H5L5.30902 3.38197Z' fill='%23E60A3C'/%3E%3C/svg%3E%0A");
  }

  &__input {
    @extend .form-control;
    width: 54px;
  }
}