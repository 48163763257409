.basket-item {
  $basket-item-class: &;

  display: flex;
  padding: 16px 0px;

  &:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $border-color;
  }

  @mixin hideImageMixin {
    #{$basket-item-class} {
      &__image {
        display: none;
      }
    }
  }

  &_deleted#{$basket-item-class} {
    @include hideImageMixin;

    #{$basket-item-class} {
      &__price-and-count {
        display: none;
      }
    }
  }

  &__image-wrapper {
    text-align: center;
    height: 96px;
    width: 96px;
    margin-right: 7px;
  }

  &__image {
    max-height: 96px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    flex-grow: 1;

    @include text-body-regular;
  }

  &__head {
    display: flex;
    @include text-small-regular;
    color: $gray-500;
  }

  &__content-name {
    flex-grow: 1;
  }

  &__content-button {
    @include text-body-medium;
    color: $info-400;
    cursor: pointer;
    width: fit-content;

    &_delete {
      &:hover,
      &:focus {
        color: $danger-400;
      }
    }
  }

  &__price-and-count {
    margin-left: 16px;

    display: flex;
    gap: 8px;
  }

  &__price {
    white-space: nowrap;
    min-width: 100px;
  }

  &__sim-price-and-count {
    cursor: pointer;
    min-width: 72px;
    color: #007AF9;
  }

  &__price-total {
    @include text-body-medium;
  }

  &__price-unit {
    @include text-small-regular;
    color: $gray-500;
  }

  &__count {
    @extend .form-control;
    width: 72px;
    height: 40px;
    text-align: center;
  }
}

.sim-card-params {
  min-width: 72px;
}

.basket_items > .basket-item:last-of-type {
  border-bottom-width: 0px;
}

.basket_items.items_short .basket-item__image-wrapper, .basket-item_deleted .basket-item__image-wrapper {
  display: none;
}
