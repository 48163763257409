//
// Modal
//
.modal {
  $padding-h: 40px;

  &.fade {
    background-color: rgba($neutral-500, 0);
    transition: opacity .05s linear .05s,
    background-color .2s ease-in-out;

    &.show {
      background-color: rgba($neutral-500, .4);
    }
  }

  .modal-dialog {
    outline: none !important;
  }

  .modal-header {
    @include border-top-radius($modal-content-border-radius);
    flex-direction: column;
    padding: 35px $padding-h 0;

    .modal-title {
      @include h4;
    }

    .modal-subtitle {
      @include text-small-medium;
      margin-top: 8px;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }

    .btn-close {
      $size: 24px;
      background: none;
      display: flex;
      height: $size;
      opacity: 1;
      order: -1;
      padding: 8px;
      width: $size;
      position: absolute;
      top: 25px;
      right: 25px;

      &:hover,
      &:focus {
        &::before {
          @include svg-bg-icon(cross, $danger-500);
        }
      }

      &:focus {
        box-shadow: 0 0 0 4px rgba($danger-500, .12);
      }

      &::before {
        content: '';
        @include svg-bg-icon(cross, $danger-300);
        background-color: $danger-100;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: $border-radius;
        display: block;
        height: $size;
        width: $size;
      }
    }
  }

  .modal-body {
    @include text-body-regular;
    padding: 24px $padding-h 0;
  }

  .modal-footer {
    flex-direction: column;
    justify-content: center;
    padding: 0 $padding-h 16px;

    & > * {
      margin: initial;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}

.modal-rounded {
  @if $enable-rounded {
    border-radius: $modal-content-border-radius !important;
  }
}
