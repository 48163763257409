.file {
  display: flex;
}

.file__icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  padding: 8px;

  width: 40px;
  height: 40px;

  background: var(--bs-gray-100);
  border-radius: 12px;
  margin-right: 12px;
  position: relative;

  &_marker {
    width: 5px;
    height: 5px;
    position: absolute;
    right: 6px;
    top: 6px;
    background-color: #E60A3C;
    border-radius: 50%;
  }
}

.file__content {
  flex-grow: 1;
}

.file__name {
  @include text-body-regular;
  flex: none;
  flex-grow: 1;
  margin-bottom: 4px;
  color: $black;

  &:hover {
    color: $info-400;
  }
}

.file__icon-image {
  color: $info-400;
}

.file__icon-image_archive {
  color: $gray-400;
}
