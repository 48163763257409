.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;

  &__image {
    height: 192px;
    width: 192px;
  }

  &__title {
    margin-top: 16.52px;
    text-align: center;

    &:after {
      $width: 48px;

      content: '';
      display: block;
      width: $width;
      border-top: #5D26FA solid 4px;
      position: relative;
      left: calc(50% - $width / 2);
    }
  }

  &__detail {
    @include text-body-regular;
    margin-top: 20px;
    text-align: center;
  }

  &__button {
    @extend .btn, .btn-primary;
    margin-top: 40px;
  }
}
