//
// File Uploader
//

.file-uploader-label {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  .btn {
    margin-top: 8px;
  }
}

.file-uploader-remove-button {
  $size: 24px;
  @include svg-bg-icon(cross, $danger);
  background-position: center calc(50% + .5px);
  background-repeat: no-repeat;
  border: none;
  border-radius: calc($size / 2);
  cursor: pointer;
  height: $size;
  width: $size;
}

.file-uploader-filename {
  @include text-body-regular;
  display: flex;
  gap: 4px;

  &.active {
    margin-top: 4px;
  }

  .file-uploader-remove-button {
    background-color: transparent;
    position: relative;
    top: -2px;
  }
}

.file-uploader-preview.active {
  $container-size: 104px;
  background-color: $neutral-500;
  border-radius: 8px;
  height: $container-size;
  margin: 8px 0;
  overflow: hidden;
  position: relative;
  width: $container-size;

  img {
    $padding: 4px;
    $image-size: calc($container-size - $padding * 2);
    display: block;
    height: $image-size;
    left: $padding;
    object-fit: contain;
    position: absolute;
    top: $padding;
    width: $image-size;
  }

  .file-uploader-remove-button {
    background-color: white;
    box-shadow: 0 -4px 16px -8px rgba(black, .08),
    0 4px 8px rgba(black, .16);
    position: absolute;
    right: 4px;
    top: 4px;

    &:focus {
      background-color: $danger-100;
      box-shadow: 0 -4px 16px -8px rgba(black, .08),
      0 4px 8px rgba(black, .16),
      inset 0 0 0 2px $danger-300;
      outline: none;
    }
  }
}

.file-uploader-input {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
