//
// Buttons Base
//

// Button
.btn {
  @include text-body-medium;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;

  &.btn-sm,
  &.btn-small, {
    @include text-small-medium;
    padding: 6px 8px;
  }

  &.btn-primary {
    color: $secondary-700;

    &:hover,
      // TODO: tune focus state in accordance with UIKit
    &:focus {
      color: $secondary-500;
    }

    &:active {
      background-color: $primary-500 !important;
      color: $secondary-700 !important;
    }
  }

  &.btn-default {
    background-color: $info-100;
    color: $info-400;

    &:hover,
      // TODO: tune focus state in accordance with UIKit
    &:focus {
      background-color: $info-200;
      color: $info-500;
    }

    &:active {
      background-color: $info-300;
      color: $info-700;
    }
  }

  &.btn-danger {
    background-color: $danger-100;
    color: $danger-400;

    &:hover,
      // TODO: tune focus state in accordance with UIKit
    &:focus {
      background-color: $danger-200;
      color: $danger-500;
    }

    &:active {
      background-color: $danger-300 !important;
      color: $danger-700 !important;
    }
  }

  &.btn-delete {
    color: $info-400;

    &:hover,
    &:focus {
      color: $danger-400;
    }
  }

  &.btn-link,
  &.btn-text {
    border: 0;
    color: $info-400;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-decoration: none;

    &:hover {
      color: $info-600;
    }

    &:focus {
      background-color: $info-200;
      color: $info-500;
    }

    &:active {
      background-color: $info-300;
      color: $info-700;
    }

    &.btn-disabled,
    &.disabled,
    &:disabled {
      &,
      &:hover,
      &:focus,
      &:active {
        background-color: transparent !important;
      }
    }
  }

  &.btn-disabled,
  &.disabled,
  &:disabled {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $secondary-100;
      color: $neutral-300;
      pointer-events: none;
      user-select: none;
    }
  }
}

.btn-close {
  @include svg-bg-icon(cross, $black)
}

.top-panel-button {
    padding: 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;

    &-grey {
        background: var(--Palette-Primary-100, #E1E2E6);
    }
    &-yellow {
        background: var(--Palette-Primary-100, #FCF6E8);
    }
}
