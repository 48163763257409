.basket-content {
  @extend .card;

  &__head {
    display: flex;

    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $border-color;

    padding: 12px;
  }

  &__head_black {
    display: flex;
    background-color: black;
    color:white;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #E1E2E6;
    padding: 12px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &__button-delete {
    @include text-body-medium;
    color: $danger-400;
    cursor: pointer;
    width: fit-content;
    margin-right: 5px;

    &:hover,
    &:focus {
      color: $danger-400;
    }
  }

  &__head-left {
    display: flex;
    flex-grow: 1;
  }

  &__head-right {
    display: flex;
    align-items: center;
  }

  &__items {
    padding: 16px 24px;
  }

  &__items > div:not(:last-of-type) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #E1E2E6;
  }
}

.basket_items.items_short .basket-item__image{
  display: none;
}
