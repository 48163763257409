.products-index-page {
	display: flex;
	gap: 24px;

	&__table {
		flex-grow: 1;
    overflow-x: auto;  // For right scroll in tables. Unknown how it work.
    position: relative;
	}

	&__filter {
		width: 224px;
		margin-top: 40px;
	}
}

.rc-handle:after {
  display: block;
  position: relative;
  content: "";
  width: 1px;
  height: 16px;
  margin-top: 12px;
  background: #E1E2E6;
}