.grid-view-common-yii-panel {

  @mixin hide-empty() {
    &:empty, &:not(:has(> *)) {
      display: none;
    }
  }

  @mixin icon-info {
    $size: 16px;
    @include svg-bg-icon(info, $neutral-300);
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    height: $size;
    width: $size;
  }

  @mixin custom-information-text {
    color: var(--Light-Text-Muted, #595A5F);
    font-variant-numeric: lining-nums tabular-nums;
    font-family: 'Beeline Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  @mixin custom-information-block {
    display: flex;
    margin-left: auto;
  }

  display: flex;
  align-items: center;

  &__left-side-template {
    width: 100%;

    display: flex;
    align-items: center;
  }

  &__summary-template {
    display: flex;
    align-items: baseline;
  }

  &__summary-count {
    @include h4;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__left-side-buttons {
    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__summary-additional {
    display: flex;
    align-items: baseline;
    margin-left: 8px;

    @include hide-empty();
  }

  $custom-options-margin: 19px;

  &__toolbar-filters {
    display: flex;
    align-items: center;

    margin-left: 24px;
    margin-right: 35px - $custom-options-margin;

    @include hide-empty();
  }

  &__custom-options {
    display: flex;
    align-items: center;
    margin-left: $custom-options-margin;
  }

  &__links-to-upload {
    display: flex;
    align-items: center;
    margin-left: $custom-options-margin;
    gap: 5px;
  }

  &__custom-information-left-icon {
    @include custom-information-block();
    @include custom-information-text();

    &::before {
      @include icon-info();
      margin-right: 8px;
    }
  }

  &__custom-information-right-icon {
    @include custom-information-block();
    @include custom-information-text();

    &::after {
      @include icon-info();
      margin-left: 8px;
    }
  }
  & p {
    @include custom-information-text();
  }
}
