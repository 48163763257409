//
// Vertical Stepper
//
.stepper-v {
  @extend .card;
  list-style: none;
  padding: 8px;

  &__item {
    border-radius: 8px;
    padding: 8px;
    position: relative;

    &:hover,
    &:focus {
      .stepper-v__item-label {
        color: $primary-500;
      }
    }

    &::before,
    &::after {
      content: '';
      display: block;
      height: 100%;
      left: 15px;
      position: absolute;
      top: 0;
      width: 2px;
      z-index: 1;
    }

    &::before {
      background-image: linear-gradient(to bottom, $neutral-200 35%, transparent 35%, transparent 100%, $neutral-200 100%);
      background-position: 0 5px;
      background-repeat: repeat-y;
      background-size: 2px 10px;
    }

    &::after {
      border: 1px solid $neutral-200;
    }

    &_past {
      &:first-child::after {
        top: 18px;
        height: auto;
        bottom: 0;
      }

      &::before {
        display: none;
      }
    }

    &_current {
      background-color: $neutral-100;
      cursor: default;
      pointer-events: none;

      &:first-child::after {
        display: none;
      }

      &:last-child::before {
        display: none;
      }

      &::before {
        bottom: 0;
        height: auto;
        top: 18px;
      }

      &::after {
        height: 18px;
      }

      .stepper-v__item-icon::before {
        background-color: $neutral-300;
      }
    }

    &_future {
      &:last-child::before {
        height: 18px;
      }

      &::after {
        display: none;
      }
    }

    &_success {
      .stepper-v__item-icon {
        @include svg-bg-icon(success);

        &::before {
          display: none;
        }
      }
    }

    &_error {
      .stepper-v__item-icon {
        @include svg-bg-icon(error);

        &::before {
          display: none;
        }
      }
    }
  }

  &__item-link {
    display: flex;
    gap: 8px;
  }

  &__item-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    height: 20px;
    width: 16px;
    position: relative;
    z-index: 2;

    &::before {
      $size: 8px;
      content: '';
      background-color: $neutral-200;
      border-radius: 50%;
      height: $size;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: $size;
    }
  }

  &__item-label {
    @include text-body-regular;
    color: $black;
    transition: color .15s ease-out;
  }

  &__item-hint {
    @include text-small-regular;
    color: $neutral-500;
  }
}
