//
// Breadcrumb
//

// Breadcrumb
.breadcrumb {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;

  // Item breadcrumb
  .breadcrumb-item {
    @include text-small-regular;
    align-items: center;
    display: flex;
    padding-left: 0;
    padding-right: $breadcrumb-item-padding-x;

    &:last-child {
      padding-right: 0;
    }

    &:after {
      content: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.125 1.88477L7.72212 5.8416C7.80881 5.93695 7.80881 6.08258 7.72212 6.17793L4.125 10.1348' stroke='%237C7D81' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      padding-top: 2px;
      padding-left: $breadcrumb-item-padding-x;
      height: 16px;
    }

    &:before {
      display: none;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      font-size: inherit;
      font-weight: $font-weight-medium;
      line-height: inherit;
    }
  }
}
