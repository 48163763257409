.h-24px {
  height: 24px !important;
}

.w-24px {
  width: 24px !important;
}

.size-24px {
  @extend .h-24px;
  @extend .w-24px;
}

.w-224px {
  width: 224px !important;
}
