.file-list-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-M, 16px);
  flex: 1 0 0;

  &__panel {
    display: flex;
    padding: var(--Spacing-M, 16px) var(--Spacing-L, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-M, 16px);
    align-self: stretch;
    border-radius: 16px;
    background: var(--Palette-Absolute-White, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

    &__header {
      color: var(--Palette-Absolute-Black, #000);
      font-family: 'Beeline Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &__row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
      align-items: flex-start;
      gap: var(--Spacing-M, 16px);
      align-self: stretch;

      &__file {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        border-radius: 16px;

        &__icon-container {
          display: flex;
          width: 40px;
          padding: var(--Spacing-XS, 8px);
          justify-content: space-between;
          align-items: flex-start;
          border-radius: 12px;
          background: var(--Color-Neutral-100, #F0F0F1);

          &__image {
            display: flex;
            justify-content: center;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
          }
        }

        &__text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          flex: 1 0 0;

          &__up {
            align-self: stretch;
            color: var(--Palette-Absolute-Black, #000);
            font-variant-numeric: lining-nums tabular-nums;
            font-family: 'Beeline Sans', sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          &__bottom {
            align-self: stretch;
            color: var(--Palette-Neutral-400, var(--Color-Neutral-400, #7C7D81));
            font-variant-numeric: lining-nums tabular-nums;
            font-family: 'Beeline Sans', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }
  }
}
