//
// Tabs
//
.nav-tabs {
  border-bottom: none;
}

.nav-item {
  &:not(:last-child) {
    margin-right: 4px;
  }

  & > * {
    @include text-body-medium;
    border-radius: $border-radius-lg;
    color: $body-color;
    cursor: pointer;
    display: block;
    padding: 6px 8px;

    &:not(.active):not(.disabled) {
      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 2px $neutral-300;
        color: inherit;
      }
    }

    &.active {
      background-color: $primary-300;

      &:hover {
        color: inherit;
      }
    }

    &.disabled {
      color: $neutral-300;
      cursor: not-allowed;
    }
  }
}
