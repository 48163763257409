//
// Links
//
.link {
  @mixin link-box-shadow($color) {
    box-shadow: 0 0 0 2px $color;
  }

  @mixin link {
    @include text-body-regular;
    border-radius: 2px;
    display: inline-block;
    outline: none;

    &.link-pseudo {
      text-decoration: underline;
      text-decoration-style: dashed;
    }

    &.link-external {
      white-space: nowrap;

      &::after {
        $size: 16px;
        @include svg-bg-icon(external, $info);
        content: '';
        display: inline-block;
        height: $size;
        margin-left: 4px;
        position: relative;
        top: 3px;
        width: $size;
      }
    }
  }

  &,
  &-primary {
    @include link;
    color: $info-400;

    &:hover,
    &:focus {
      @include link-box-shadow($info-100);
      background-color: $info-100;
      color: $info-500;
    }
  }

  &-secondary {
    @include link;
    color: $neutral-500;

    &:hover,
    &:focus {
      @include link-box-shadow($neutral-100);
      background-color: $neutral-100;
      color: $neutral-600;
    }
  }

  &-danger {
    @include link;
    color: $danger-400;

    &:hover,
    &:focus {
      @include link-box-shadow($danger-100);
      background-color: $danger-100;
      color: $danger-500;
    }
  }

  &-light {
    @include link;
    color: $white;

    &:hover,
    &:focus {
      @include link-box-shadow($neutral-700);
      background-color: $neutral-700;
      color: $white;
    }
  }

  &-dark {
    @include link;
    color: $black;

    &:hover,
    &:focus {
      @include link-box-shadow($neutral-100);
      background-color: $neutral-100;
      color: $black;
    }
  }
}
