/* Чтобы не поломать верстку где она уже была, это меню можно найти в отчётах дилера */
.categories-list .vertical-menu {
  ul {
    padding: 0px;
    margin: 0px;

    li {
      a {
        margin-left: 24px;
      }

      a:hover {
        color: #F5A207;
      }

      &::before {
        color: #E1E2E6;
      }
    }

    li.active {
      a:hover {
        color: #000000;
      }

      &::before {
        color: #B6B7B9;
      }
    }

    li.hidden {
      display: none;
    }
  }
}
