.preorder-detail-visual-control {
  @extend .card;

  margin-top: 24px;

  &__head {
    padding: 16px 24px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #E1E2E6;

    display: flex;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    flex-grow: 1;
  }

  &__success-icon {
    margin-right: 1em;
  }

  &__title-head {
    @include h4;
  }

  &__title-info {
    display: flex;
    @include text-small-regular;
  }

  &__left {
    //display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__body {
    margin: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}