.report-file-body {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--palette-absolute-white, #FFF);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

  &__head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    &__title {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--Palette-Absolute-Black, #000);
      text-align: center;
      font-family: 'Beeline Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &__subtitle {
      color: var(--light-text-muted, #595A5F);
      text-align: center;
      font-variant-numeric: lining-nums tabular-nums;
      font-family: 'Beeline Sans', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__grid {
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));

    &__card {
      display: flex;
      width: 200px;
      align-items: flex-start;
      gap: 12px;
      flex-shrink: 0;
      border-radius: 16px;

      &__icon-container {
        display: flex;
        width: 40px;
        padding: 8px;
        justify-content: space-between;
        align-items: flex-start;
        flex-shrink: 0;
        border-radius: 12px;
        background: var(--palette-neutral-100, #F0F0F1);

        &__icon-hourglass {
          @include svg-bg-icon(hourglass);
          content: '';
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }

        &__icon-error {
          @include svg-bg-icon(errorFile);
          content: '';
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }

        &__icon-document {
          @include svg-bg-icon(document);
          content: '';
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }
      }
      &__text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;

        &__main-text {
          color: var(--palette-absolute-black, #000);
          font-variant-numeric: lining-nums tabular-nums;
          font-family: 'Beeline Sans', sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        &__sub-text {
          color: var(--palette-neutral-400, #7C7D81);
          font-variant-numeric: lining-nums tabular-nums;
          font-family: 'Beeline Sans', sans-serif;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }
}

.report-file-link-hover:hover {
  color: var(--light-text-link, #007AFF) !important;
}
