//
// Layout Config
//

// Root font Sizes
$root-font-size: 16px; // Root font size for desktop mode
$root-font-size-lg: 16px; // Root font size for tablet mode
$root-font-size-md: 16px; // Root font size for mobile mode

// Page bg
$page-bg: if(isDarkMode(), #151521, $gray-100) !default;

// Content border radius
$content-border-radius: 1.5rem !default;

// Content Spacing
$content-spacing: (
        desktop: 24px, // Padding for desktop mode
        tablet-and-mobile: 16px // Padding for tablet and mobile modes
) !default;

// Header
$header-config: (
  // Default Mode
        default: (
                height: (
                        desktop: 56px,
                        tablet-and-mobile: 56px
                ),
                bg-color: if(isDarkMode(), #1E1E2D, $white),
        ),
  // Fixed Mode
        fixed: (
                height: (
                        desktop: 56px,
                        tablet-and-mobile: 56px
                ),
                top: 16px,
                z-index: 100,
                bg-color: if(isDarkMode(), #1E1E2D, $white),
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08)
        )
) !default;

// Toolbar
$toolbar-config: (
        z-index: 99,
        height: (
                desktop: 56px,
                tablet-and-mobile: 56px
        ),
        padding: 0.5rem 0,
        bg-color: if(isDarkMode(), darken(#1e1e2d, 2%), $white),
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08),
        border-top: if(isDarkMode(), 0, 1px solid $border-color)
) !default;

// Aside
$aside-config: (
        z-index: 101,
        transition-speed: 0.3s,
        padding-x: 24px,
        menu-indention: 12px,
        bg-color: transparent,
        logo-bg-color: transparent,
        scrollbar-color: rgba($black, .24),
        scrollbar-hover-color: rgba($black, .48),
        width: 318px,
        width-content: 330px,
        minimized-width: 88px,
        box-shadow: none,
        minimized-hover-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08)
) !default;

// Footer
$footer-config: (
        bg-color: if(isDarkMode(), #1B1B28, $white),
) !default;

// Colors
:root {
  @import "../components/_variables.scss";

  --app-black: #{$black};
  --app-info-100: #{$info-100};
  --app-info-200: #{$info-200};
  --app-info-300: #{$info-300};
  --app-info-400: #{$info-400};
  --app-info-500: #{$info-500};
  --app-info-600: #{$info-600};
  --app-info-700: #{$info-700};
  --app-info: #{$info};
  --app-primary-100: #{$primary-100};
  --app-primary-500: #{$primary-500};

  --app-scroll: #AFAFAF;
}