.confirm-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    margin-bottom: 18px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__file-name {
    @include text-small-medium;
    margin-bottom: 24px;
  }

  &__content {
    @include text-body-regular;
    display: flex;
    align-items: start;
    text-align: left;
    padding: 16px;
    gap: 4px;
    background: #EBF4FF;
    border: 2px solid #007AFF;
    border-radius: 8px;
  }
}
