// Поле ввода "Поиск"
.search-input {
  position: relative;

  & input {
    padding-left: 30px;
  }

  &::before {
    @include svg-bg-icon(search, $gray-400);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto 0;
    width: 16px;
    height: 16px;
    background-size: cover;
  }
}
