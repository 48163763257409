.agreement-subscriptions__main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.agreement-subscriptions__body {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;

  &__block {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }
}

.agreement-subscriptions-form-label {
  font-size: 0.8125rem;
  font-weight: 500;
}

.agreement-subscriptions__buttons {
  @include text-body-medium;
  background: #EBF4FF;
  border-radius: 8px;
  justify-content: center;
  display: flex;
  padding: 10px 16px;
  align-items: flex-start;
  width: 172px;
  height: 40px;
  align-self: flex-end;

  &__icon {
    display: flex;
    align-items: center;
    gap: 8px;

    &::after {
      @include svg-bg-icon(arrowRight, #007AFF);
      content: '';
      width: 16px;
      height: 16px;
    }
  }
}

.d-contents {
  display: contents !important;
}
