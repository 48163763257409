.documents-btn-file-close {
    position: absolute;
    right: 2px;
    top: 3px;
    border-radius: 20px;
    opacity: 1;
    background: white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='red'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    background-size: 11px;
    box-shadow: 0 -4px 16px -8px rgba(0, 0, 0, 0.08), 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}
