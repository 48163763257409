//
// Badge
//
.badge {
  align-items: center;
  display: inline-flex;
  height: $badge-size;
  flex-shrink: 0;
  justify-content: center;
  min-width: $badge-size;
  padding: 7px 8px 5px;

  &.badge-default {
    background-color: $neutral-200;
    box-shadow: inset 0 0 0 2px $neutral-300;
    color: $neutral-600;
  }

  &.badge-danger {
    background-color: $danger-100;
    box-shadow: inset 0 0 0 2px $danger-200;
    color: $danger-600;
  }

  &.badge-warning {
    background-color: $primary-100;
    box-shadow: inset 0 0 0 2px $primary-400;
    color: $primary-700;
  }

  &.badge-success {
    background-color: $success-100;
    box-shadow: inset 0 0 0 2px $success-200;
    color: $success-600;
  }

  &.badge-info {
    background-color: $info-100;
    box-shadow: inset 0 0 0 2px $info-300;
    color: $info-700;
  }

  &.badge-sm {
    border-radius: 6px;
    font-size: $badge-font-size-sm;
    height: $badge-size-sm;
    min-width: $badge-size-sm;
  }

  &.badge-lg {
    font-size: $badge-font-size-lg;
    min-width: $badge-size-lg;
  }

  &.badge-no-border {
    box-shadow: none !important;
  }
}
