.preorder-processing-detail-item {
  $order-detail-item-class: &;

  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  &:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $border-color;
  }

  &__content {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    flex-grow: 1;

    @include text-body-regular;
  }

  &__head {
    display: flex;
    @include text-small-regular;
    color: $gray-500;
  }

  &__content-name {
    flex-grow: 1;
  }

  &__price-and-count {
    margin-left: 16px;

    display: flex;
    gap: 8px;
  }

  &__price {
    white-space: nowrap;
  }

  &__price-total {
    @include text-body-medium;
  }

  &__price-unit {
    @include text-small-regular;
    color: $gray-500;
  }

  &__cart {
    display: flex;
    min-width: 190px;
    gap: 10px;
  }

  &__cart button {
    min-width: 115px;
  }

  &__cart-btn, &__cart-input {
    flex-grow: 1;
  }

  &__cart-btn {
    min-width: 190px;
  }

  &__input {
    @extend .form-control;
    text-align: center;
    width: 60px;
    -webkit-appearance: none;
  }

  &__input[type=number] {
    -moz-appearance: textfield;
  }

  &__input::-webkit-outer-spin-button,
  &__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}