//
// Form Hint
//
.form-hint,
.input-hint,
.select-hint,
.dropdown-hint,
.datepicker-hint,
.invalid-feedback {
  @include text-small-regular;
  color: $neutral-500;
  margin-top: 4px;
  padding-left: 12px;
  padding-right: 12px;
}

//
// Invalid Feedback
//
.invalid-feedback {
  @include text-small-medium;
  color: $danger-400;
}
