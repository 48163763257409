/* .ttcn stands for trade-tariffs-change-notification */

.ttcn {
  @extend .card, .card-body;
  gap: 16px;

  @media (min-width: 600px) {
    align-items: center;
    flex-direction: row;
  }

  &__file {
    flex-grow: 1;
  }

  &__side {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    min-width: 326px;
  }

  &__button-hint {
    @include text-small-regular;
    align-items: center;
    color: $neutral-500;
    display: flex;

    &::after {
      $size: 16px;
      @include svg-bg-icon(info, $neutral-300);
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      height: $size;
      margin-left: 8px;
      width: $size;
    }
  }

  &__status {
    @include text-body-regular;
    align-items: center;
    display: flex;

    &::before {
      $size: 24px;
      @include svg-bg-icon(success);
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      height: $size;
      margin-right: 8px;
      width: $size;
    }
  }
}