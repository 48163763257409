.promo-code-widget {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }

  &__body {
    margin-top: 16px;

    input[type=text] {
      margin-bottom: 8px;
    }

    &[data-kt-promo-detail-toggle="on"] {
      display: none;
    }

    p {
      font-size: 13px;
      line-height: 16px;
      color: #595A5F;
    }
  }
}


.promo-toggle {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__angle_up {
    display: block;
  }

  &__angle_down {
    display: none;
  }
}

.promo-toggle.active .promo-toggle__angle_up{
  display: none;
}

.promo-toggle.active .promo-toggle__angle_down{
  display: block;
}