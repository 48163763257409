.bird-on-sign {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;

  img {
    width: 183px;
    height: 179px;
  }

  p {
    margin-top: 20px;
  }
}
