.products-grid {
  display: grid;
  flex-grow: 1;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  margin-right: 24px;

  &__item {
    @extend .card;
    align-items: flex-start;
    padding: 16px;
    align-self: stretch;
    z-index: 1;
    color: inherit !important;
    cursor: pointer;
    outline: none;
  }

  &__item_top_title {
    height: 16px;
    flex: 1 0 0;
    overflow: hidden;
    color: var(--palette-neutral-500, #595A5F);
    font-variant-numeric: lining-nums tabular-nums;
    text-overflow: ellipsis;
    font-family: 'Beeline Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__item-container {
    display: flex;
    align-items: flex-end;
    align-self: stretch;
  }

  &__item-container div {
    margin-right: 0px;
  }

  &__item-price {
    @include text-large-regular;
  }

  &__item-name {
    @include text-body-medium;
    position: relative;

    &::before {
      @include svg-bg-icon(gift, $danger-400);
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      height: 12px;
      position: relative;
      width: 20px;
    }
  }

  &__item-calc {
    margin-left: auto;
    display: flex;

    &::after {
      @include svg-bg-icon(calc, $info-500);
      content: '';
      background-repeat: no-repeat;
      display: inline-block;
      height: 24px;
      width: 24px;
      background-position: center;
    }
  }

  &__item-cup {
    margin-left: auto;
    display: flex;

    &::after {
      @include svg-bg-icon(cupPresent, $primary-400);
      content: '';
      height: 24px;
      width: 24px;
    }
  }

  &__block {
    display: inline-block;
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  &__item-shop {
    margin-left: auto;
    display: flex;

    &::after {
      @include svg-bg-icon(shop);
      content: '';
      height: 24px;
      width: 24px;
    }
  }
}
