.preorder-toggle {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__angle_up {
    display: block;
  }

  &_active &__angle_up {
    display: none;
  }

  &__angle_down {
    display: none;
  }

  &_active &__angle_down {
    display: block;
  }

}