//
// Daterangepicker
//
.kv-drp-container {
  .form-control {
    background-color: $input-bg !important;
    padding-left: $input-padding-x !important;
    padding-right: 48px;
  }

  .right-ind:not(.kv-clear) {
    display: none;
  }

  .kv-clear {
    $size: 24px;
    @include svg-bg-icon(cross, $neutral-400);
    background-position: center !important;
    background-repeat: no-repeat;
    color: transparent;
    cursor: pointer;
    height: $size !important;
    margin-top: calc($size / -2 + 1px);
    right: 24px !important;
    top: 50% !important;
    width: $size !important;

    &:hover,
    &:focus {
      @include svg-bg-icon(cross, $danger-400);
    }
  }

  .left-ind {
    $size: 16px;
    @include svg-bg-icon(calendar);
    background-position: center !important;
    background-repeat: no-repeat;
    cursor: pointer;
    height: $size;
    left: initial !important;
    margin-top: calc($size / -2);
    right: 8px !important;
    top: 50% !important;
    width: $size !important;

    i {
      display: none;
    }
  }
}

.daterangepicker {
  @include text-body-regular;
  border: none !important;
  border-radius: $border-radius-lg !important;
  box-shadow: $box-shadow;
  font-family: $font-family-sans-serif !important;
  margin-top: 0 !important;

  &::before,
  &::after {
    display: none !important;
  }

  &.show-ranges {
    $periods-width: 184px;
    $intersection-width: 8px;
    margin-left: calc($periods-width - $intersection-width);

    .ranges {
      background-color: $white;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      float: initial !important;
      left: calc($periods-width * -1 + $intersection-width);
      margin-top: 16px !important;
      padding: 4px;
      position: absolute;
      width: $periods-width !important;

      ul {
        margin: 0 !important;
        width: auto !important;
      }

      li {
        @mixin hover {
          &:hover,
          &:focus {
            background-color: $neutral-100 !important;
          }
        }

        @include text-body-regular;
        border-radius: 6px;
        padding: 10px 16px 10px 32px !important;

        @include hover;

        &.active {
          @include svg-bg-icon(check, $success-400);
          background-color: transparent !important;
          background-position: 8px center;
          background-repeat: no-repeat;
          color: inherit !important;

          @include hover;
        }
      }
    }
  }

  .drp-calendar {
    $cell-size: 32px;
    $cell-margin: 2px;

    border: none !important;
    max-width: initial !important;
    padding: 16px 24px !important;

    &.right {
      padding-left: 0 !important;
    }

    thead,
    tbody,
    tr,
    th,
    td {
      display: flex;
    }

    thead,
    tbody {
      flex-direction: column;
    }

    th,
    td {
      align-items: center;
      justify-content: center;
    }

    thead {
      border-bottom: 1px solid $neutral-200;
      margin-bottom: 16px;
      padding-bottom: 4px;

      th.prev,
      th.next {
        $size: 40px;
        background-repeat: no-repeat;
        border-radius: $border-radius;
        flex-shrink: 0;
        height: $size;
        width: $size;

        span {
          display: none;
        }
      }

      th.prev {
        @include svg-bg-icon(angleLeft);
        background-position: calc(50% - 1px) center;
      }

      th.next {
        @include svg-bg-icon(angleRight);
        background-position: calc(50% + 1px) center;
      }

      th.month {
        @include text-large-regular;
        height: auto !important;
        padding: 7px 0;
        width: 100%;
      }

      tr:last-child {
        th {
          @include text-small-regular;
          color: $neutral-500;
          height: $cell-size;
          margin: $cell-margin;
          text-transform: uppercase;
          width: $cell-size;
        }
      }
    }

    .calendar-table tbody td {
      @include text-body-regular;
      border: none;
      border-radius: 50%;
      height: $cell-size;
      margin: $cell-margin;
      width: $cell-size;

      &:not(.active) {
        &:hover,
        &:focus {
          background-color: transparent !important;
          box-shadow: inset 0 0 0 2px $neutral-300;
        }
      }

      @mixin background($color) {
        content: '';
        background-color: $color;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
      }

      &.today {
        position: relative;
        z-index: 1;

        &::after {
          @include background(transparent);
          @include svg-bg-icon(bullet, $accent-violet);
          background-position: center 21px;
          background-repeat: no-repeat;
          background-size: 11px;
        }
      }

      &.active,
      &.in-range:hover {
        background-color: transparent !important;
        color: inherit !important;
        position: relative;
        z-index: 1;

        &::after {
          @include background($primary-300);
          border-radius: 50%;
        }
      }

      &.start-date,
      &.in-range:first-child {
        &::before {
          @include background($primary-200);
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
          left: 0 !important;
        }
      }

      &.end-date,
      &.in-range:last-child {
        &::before {
          @include background($primary-200);
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
          right: 0 !important;
        }
      }

      &.in-range:not(.start-date):not(.end-date) {
        background-color: transparent !important;
        position: relative;
        z-index: 1;

        &::before {
          @include background($primary-200);
          left: -4px;
          right: -4px;
        }
      }

      &.off.ends {
        color: $neutral-300;
      }

      &.weekend {
        color: $danger-500;

        &.off.ends {
          color: $danger-200;
        }
      }

      &.disabled {
        color: $neutral-300 !important;

        &:hover,
        &:focus {
          background-color: transparent !important;
          box-shadow: none;
        }

        &::before {
          display: none;
        }

        &::after {
          background-color: transparent;
        }
      }
    }
  }

  .drp-buttons {
    border-top: none !important;
    padding: 8px 24px 16px !important;

    .drp-selected {
      display: none;
    }

    .btn {
      font-family: inherit;
      font-size: inherit !important;
      font-weight: 500 !important;
      margin-left: inherit !important;
      padding: $btn-padding-y $btn-padding-x !important;
    }
  }
}
.kv-drp-dropdown .range-value {
  color: #555;
}
