.preorder-processing-info-item {
  display: flex;

  &__col-icon {
    width: 36px;
    flex-grow: 0;
    margin-right: 1em;
    text-align: center;
  }

  &__col-content {
    flex-grow: 1;
  }

  &__title {
    @include text-large-bold;
  }
}