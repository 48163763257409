.products-report-body {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;

  &__head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
  }

  &__divider {
    border-right: 1px solid var(--palette-neutral-200, #E1E2E6);
  }

  &__input_date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.grid-view-common-yii-panel {
  display: flex;
  align-items: center;
}

.detailed-products-report-body {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-self: stretch;
}

.report-panel {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
  }

  &__summary {
    display: flex;
    align-items: baseline;
    gap: 12px;

    &__text {
      color: var(--palette-absolute-black, #000);
      text-align: center;
      font-family: 'Beeline Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      white-space: nowrap;
    }
  }
}

/* Устанавливаем ширину поля ввода */
span.select2-container {
  width: 100% !important;
}

/* Устанавливаем ширину выпадающего списка */
.select2-dropdown {
  width: auto !important;
}
.upload-not-found {
  display: flex;
  padding-bottom: 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    &__text {
      display: flex;
      max-width: 500px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      &__title {
        align-self: stretch;
        color: var(--light-text-main, #000);
        text-align: center;
        font-family: 'Beeline Sans', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      &__detail {
        align-self: stretch;
        color: var(--light-text-main, #000);
        text-align: center;
        font-variant-numeric: lining-nums tabular-nums;
        font-family: 'Beeline Sans', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
.products-report-body__input_date .kv-drp-dropdown .range-value {
  width: 215px;
  margin-left: -23px;
}

#reportssearch-period-kvdate {
  margin-left: -27px;
}
