.card-message {
  @extend .card;

  padding: 16px;

  &__header {
    display: flex;
    @include text-large-bold;
    gap: 8px;

    margin-bottom: 16px;

    $img-error: url("data:image/svg+xml,%3Csvg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.95541 1.31436C10.1993 0.514724 11.7969 0.520691 13.0347 1.3296L19.7637 5.72695C20.8185 6.41625 21.4567 7.58922 21.4623 8.84927L21.4908 15.1918C21.4965 16.4738 20.847 17.6698 19.7686 18.3631L13.0278 22.6964C11.7926 23.4904 10.2074 23.4904 8.97217 22.6963L2.22216 18.357C1.14883 17.667 0.500005 16.4786 0.500004 15.2026L0.5 8.79747C0.499999 7.5215 1.1488 6.33309 2.22211 5.64308L8.95541 1.31436Z' fill='%23E60A3C'/%3E%3Cpath d='M9.90821 2.56776C10.5741 2.14638 11.4239 2.14954 12.0866 2.57585L19.0013 7.02388C19.5992 7.4085 19.9619 8.06951 19.9651 8.78044L19.9947 15.2691C19.998 15.9924 19.6289 16.6665 19.0177 17.0532L12.0828 21.4416C11.4216 21.8601 10.5784 21.8601 9.91716 21.4416L2.97706 17.0498C2.36874 16.6649 2.00001 15.9952 2 15.2753L2 8.72859C2 8.00872 2.36873 7.33902 2.97702 6.95407L9.90821 2.56776Z' fill='%23FFADAE'/%3E%3Cpath d='M13.4697 8.46967C13.7626 8.17678 14.2374 8.17678 14.5303 8.46967C14.8232 8.76256 14.8232 9.23744 14.5303 9.53033L12.0607 12L14.5303 14.4697C14.8232 14.7626 14.8232 15.2374 14.5303 15.5303C14.2374 15.8232 13.7626 15.8232 13.4697 15.5303L11 13.0607L8.53033 15.5303C8.23744 15.8232 7.76256 15.8232 7.46967 15.5303C7.17678 15.2374 7.17678 14.7626 7.46967 14.4697L9.93934 12L7.46967 9.53033C7.17678 9.23744 7.17678 8.76256 7.46967 8.46967C7.76256 8.17678 8.23744 8.17678 8.53033 8.46967L11 10.9393L13.4697 8.46967Z' fill='%23C0083A'/%3E%3C/svg%3E%0A");

    $img-warning: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.64028 5.03505C9.59177 3.39637 10.0675 2.57703 10.6431 2.22949C11.4775 1.72559 12.5225 1.72559 13.3569 2.22949C13.9325 2.57703 14.4082 3.39637 15.3597 5.03505L22.1115 16.6631C23.07 18.3139 23.5493 19.1394 23.5647 19.814C23.587 20.7922 23.0635 21.7016 22.2063 22.1734C21.6152 22.4989 20.6607 22.4989 18.7518 22.4989H5.24822C3.33927 22.4989 2.38479 22.4989 1.79364 22.1734C0.936515 21.7016 0.412962 20.7922 0.435287 19.814C0.450685 19.1394 0.929962 18.3139 1.88851 16.6631L8.64028 5.03505Z' fill='%23F5A207'/%3E%3Cpath d='M10.5597 4.73313C10.9676 4.02996 11.1715 3.67838 11.4182 3.5293C11.776 3.31315 12.224 3.31315 12.5818 3.5293C12.8285 3.67838 13.0324 4.02997 13.4403 4.73313L21.4248 18.4995C21.8351 19.2069 22.0402 19.5606 22.0467 19.8496C22.0561 20.2688 21.8317 20.6583 21.4644 20.8605C21.2111 20.9999 20.8022 20.9999 19.9845 20.9999H4.01549C3.19776 20.9999 2.7889 20.9999 2.53559 20.8605C2.16832 20.6583 1.94391 20.2688 1.95332 19.8496C1.95981 19.5606 2.16494 19.2069 2.57521 18.4995L10.5597 4.73313Z' fill='%23FCEAB6'/%3E%3Cpath d='M10.89 9.17442C10.8623 8.64244 11.4191 8.25 12.0092 8.25C12.5994 8.25 13.1434 8.63372 13.125 9.1657L12.825 14.189C12.8066 14.7122 12.5441 15 12 15C11.4744 15 11.2177 14.7122 11.19 14.1802L10.89 9.17442Z' fill='%23B87407'/%3E%3Cpath d='M12 19.125C12.8284 19.125 13.5 18.5374 13.5 17.8125C13.5 17.0876 12.8284 16.5 12 16.5C11.1716 16.5 10.5 17.0876 10.5 17.8125C10.5 18.5374 11.1716 19.125 12 19.125Z' fill='%23B87407'/%3E%3C/svg%3E%0A");

    &_img {
      &_warning {
        &::before {
          content: $img-warning;
        }
      }

      &_error {
        &::before {
          content: $img-error;
        }
      }
    }

  }

  &__ul {
    margin: 0;
    list-style: none;
    padding: 0 0 0 10px;

    li {
      @include text-body-regular;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      $dot-h-w: 6px;
      $margin: $dot-h-w + 17px;

      margin-left: $margin;
      position: relative;

      &::before {
        content: '';
        margin-left: -$margin;
        position: absolute;
        top: 8px;
        width: $dot-h-w;
        height: $dot-h-w;
        background-color: $gray-300;
        border-radius: 50%;
      }
    }
  }
}