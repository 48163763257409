.back-url {
  display: flex;
}

.back-url__icon {
  margin-right: 6px;
}

.back-url__label {
  @include text-body-regular;
}