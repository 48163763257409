//
// Scroll
// Во всех браузерах isMacLike системные стили.
// На виндовсе в хроме кастомное поведение и стили.
// На виндовсе в FireFox кастомные стили.
//

@mixin set-color-chrome($color) {
  &::-webkit-scrollbar-thumb {
    background-color: $color;
  }
}

@mixin base-scroll-firefox {
  scrollbar-width: thin;

  scrollbar-color: get($scrollbar, color) transparent;

  &::-moz-scrollbar-button {
    display: none;
  }
}

@mixin base-scroll-chrome {

  &::-webkit-scrollbar {
    width: get($scrollbar, width) + get($scrollbar, space);
    height: get($scrollbar, height) + get($scrollbar, space);
  }

  &::-webkit-scrollbar * {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    -webkit-border-radius: 9999px;
    border-radius: 9999px;
    border: #{get($scrollbar, space)} solid transparent;

    min-height: get($scrollbar, min-size);
    min-width: get($scrollbar, min-size);
  }

  &::-webkit-scrollbar-track {
    display: none;
    color: transparent;
    background: transparent;
  }

  &:hover:not(.hover-scroll-overlay-y) {
    @include set-color-chrome(get($scrollbar, color));
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    display: none;
  }
}

.scroll_firefox {
  @include base-scroll-firefox;
}

.scroll_chrome {
  @include base-scroll-chrome;
}

.scroll-x {
  overflow-x: scroll;

  &.scroll_chrome {
    overflow-x: overlay;
  }
}

.scroll-y {
  overflow-y: scroll;

  &.scroll_chrome {
    overflow-y: overlay;
  }
}

.hover-scroll-overlay-y {
  $root-class: &;

  @extend .scroll-y;

  // App hover overflow overlay. Work only with js.
  &.scroll_chrome {
    @include set-color-chrome(transparent);

    &#{$root-class}_show-scroll {
      @include set-color-chrome(get($scrollbar, color));
    }
  }
}