.flex-main-statistic {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    flex-direction: column;
  }
}

.stat-card-body {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--palette-absolute-white, #FFF);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  flex-grow: 1;

  &__title {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--palette-absolute-black, #000);
    font-family: 'Beeline Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  &__input_block {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  &__content_block {
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
  }

  &__input_date_range {
    display: flex;
    height: 40px;
    padding-left: 0px;
    border-radius: 8px;
    flex-shrink: 0;
  }

  &__input_select {
    height: 40px;
    border-radius: 8px;
    padding-left: 0px;
    flex: 1 0 0;
  }
}

.income-card-body {
  border-radius: 16px;
  background: var(--palette-absolute-white, #FFF);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &__title {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--palette-absolute-black, #000);
    font-family: 'Beeline Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  &__block {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  &__input_date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__pie_chart {
    width: 179px;
    height: 180px;
  }

  &__prompt {
    color: var(--palette-neutral-500, #595A5F);
    font-family: 'Beeline Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }
}

.income-card-body__input_date .kv-drp-dropdown .range-value {
  width: 215px;
}


#chart_column {
  width: 100%;
}

@media (min-width: 768px) {
  #chart_column {
    width: 99%;
  }
  .flex-main-statistic__row {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .flex-main-statistic__row {
    flex-direction: column !important;
  }
}

@media (min-width: 1200px) {
  .flex-main-statistic__row {
    flex-direction: row !important;
  }
}

.legend-chart-column {
  display: flex;
  padding-top: 8px;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__checkbox {
    display: flex;
    width: 20px;
    height: 20px;
    align-items: flex-start;
    flex-shrink: 0;
  }
  &__item-text {
    color: var(--light-text-main, #000);
    font-family: 'Beeline Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.stat-card-body__input_date_range .select2-container {
  width: 100px !important;
}

.tooltip-chart-column {
  position: relative;
  z-index: 10;
  color: var(--Palette-Absolute-White, #FFF);
  font-family: 'Beeline Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  line-height: 16px;
  font-weight: 400;
  &__header {
    font-size: 12px;
    font-weight: 500;
  }
  &__body {
    padding: 1px;
    float: right;
    padding-left: 4px;
  }
}
