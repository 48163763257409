.grid-items {
  @extend .card;
  margin-top: 24px;

  &__head {
    padding: 16px 20px;

    display: flex;
    justify-content: space-between;
  }

  &__toggle {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__arrow {
    transition: transform 0.3s ease-in-out;
  }

  &__opened {
    transform: rotate(180deg);
  }

  .rid-items-fade-enter-active, .rid-items-fade-leave-active {
    transition: opacity .3s ease-in-out;
  }
  .rid-items-fade-enter, .rid-items-fade-leave-to {
    opacity: 0;
  }

  &__body {
    padding: 0px 20px 16px;
  }

  &__head-left-column {
    display: flex;
    align-items: center;
  }

  &__head-right-column {
    align-items: center;
  }

  &__head-right-column > span {
    display: flex;
    align-items: center;
    text-align: center;
    height: 32px;
    line-height: 20px;
    font-size: 15px;
    color: #593C04;
    border: 2px solid #FFBB3D;
    border-radius: 0.5em;
    background-color: #FCF6E8;
    padding: 0px 8px 0px 8px;
  }

  &__head-title {
    @include text-body-medium;
    margin-right: 16px;
    margin-left: 28px;
  }

  &__head-info {
    display: flex;
    @include text-small-regular;
    align-items: center;
    gap: 5px;
  }

  &__head-info-price {
    @include text-body-medium;
  }
}



.grid-item {
  $grid-item-class: &;

  display: flex;
  padding: 16px 0px;

  &:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $border-color;
  }

  &__content {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    flex-grow: 1;

    @include text-body-regular;
  }

  &__head {
    display: flex;
    @include text-small-regular;
    color: $gray-500;
  }

  &__content-name {
    flex-grow: 1;
  }

  &__price-and-count {
    margin-left: 16px;
    display: flex;
    gap: 8px;
  }

  &__price {
    white-space: nowrap;
  }

  &__price-total {
    @include text-body-medium;
  }

  &__price-unit {
    @include text-small-regular;
    color: $gray-500;
  }
}
