//
// Filter Dropdown
//
.filter-dropdown {
  $padding-x: 16px;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  display: inline-block;
  max-width: 360px;
  min-width: 192px;
  overflow: hidden;
  position: relative;

  &_search {
    .filter-dropdown__search {
      display: block;
    }

    .filter-dropdown__content {
      padding: 48px $padding-x;
    }
  }

  &_content {
    .filter-dropdown__search,
    .filter-dropdown__reset {
      $blur: 6px;
      -webkit-backdrop-filter: blur($blur);
      backdrop-filter: blur($blur);
      background-color: rgba($white, .88);
      left: get($scrollbar, width);
      position: absolute;
      right: get($scrollbar, width); // to unblur content scrollbar
      z-index: 2;
    }

    .filter-dropdown__search {
      top: 0;
      padding: 16px calc($padding-x - get($scrollbar, width)) 8px;
    }

    .filter-dropdown__reset {
      bottom: 0;
      padding: 4px calc($padding-x - get($scrollbar, width));
    }

    .filter-dropdown__content {
      display: flex;
    }
  }

  &__search {
    display: none;
    padding: 16px $padding-x 8px;
  }

  &__search-field {
    margin-bottom: initial;
    width: 100%;
  }

  &__reset {
    text-align: center;
    padding: 4px $padding-x;
  }

  &__content {
    align-items: flex-start;
    display: none;
    flex-direction: column;
    max-height: 464px;
    padding: 0 $padding-x 48px;
    overflow-y: auto;

    & > * {
      flex-shrink: 0;
      margin-top: 16px;
    }
  }
}
