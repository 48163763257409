//
// Header
//
.header {
  padding: 16px;
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    height: get($header-config, default, height, desktop);
    transition: left get($aside-config, transition-speed) ease;

    // Fixed header & sticky header modes
    .header-fixed & {
      position: fixed;
      top: get($header-config, fixed, top);
      right: get($aside-config, padding-x);
      left: 0;
      z-index: get($header-config, fixed, z-index);
      background-color: get($header-config, fixed, bg-color);
      height: get($header-config, fixed, height, desktop);
      box-shadow: get($header-config, fixed, box-shadow);
      border-radius: $border-radius-lg;
    }

    // Fixed header and fixed toolbar modes
    .header-fixed.toolbar-fixed & {
      box-shadow: none;
    }

    // Aside default enabled, aside fixed & header sticky modes
    .aside-enabled.aside-fixed.header-fixed & {
      left: get($aside-config, width-content);
      transition: left get($aside-config, transition-speed) ease;
    }

    // Aside default enabled, aside fixed, header sticky & aside minimize modes
    .aside-enabled.aside-fixed.header-fixed[data-kt-aside-minimize="on"] & {
      left: get($aside-config, minimized-width);
      transition: left get($aside-config, transition-speed) ease;
    }

    // Header menu
    .header-menu {
      display: flex;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: get($header-config, default, height, tablet-and-mobile);
    position: relative;
    z-index: 3;

    // Fixed header and header sticky modes
    .header-tablet-and-mobile-fixed & {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: get($header-config, fixed, z-index);
      height: get($header-config, fixed, height, tablet-and-mobile);
      min-height: get($header-config, fixed, height, tablet-and-mobile);
      background-color: get($header-config, fixed, bg-color);

      @if isDefaultSkin() {
        box-shadow: get($header-config, fixed, box-shadow);
      }
    }

    // Fixed header and fixed toolbar modes
    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
      box-shadow: none;
    }

    // Header menu
    .header-menu {
      display: none;
      z-index: get($header-config, fixed, z-index) - 1;
    }

    // Page title
    .page-title {
      display: none !important;
    }
  }
}

.userpic {
  $size: 40px;
  border-radius: $border-radius;
  height: $size;
  overflow: hidden;
  width: $size;

  img {
    width: 100%;
  }
}

.string-avatar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 40px;
  height: 40px;
  background: $info-100;
  border-radius: 8px;
  @include text-body-medium;
  color: #007AFF;

  &:hover {
    cursor: pointer;
  }
}
