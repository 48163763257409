//
// Main
//


// Body
body {
  background-color: $page-bg;
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    padding: 0 get($content-spacing, desktop);
  }

  // Wrapper
  .wrapper {
    $card-box-shadow-size: 4px;

    padding-right: get($aside-config, padding-x);
    position: relative;
    transition: padding-left get($aside-config, transition-speed) ease, margin-right get($aside-config, transition-speed) ease;

    &::before {
      content: '';
      background-color: $page-bg;
      height: calc(get($header-config, fixed, top) + get($header-config, fixed, height, desktop) - $border-radius-lg);
      left: calc(get($aside-config, width) - $card-box-shadow-size);
      position: fixed;
      right: calc(get($aside-config, padding-x) - $card-box-shadow-size);
      top: 0;
      z-index: 4;
    }

    .aside-enabled.aside-fixed.header-fixed[data-kt-aside-minimize="on"] &::before {
      left: calc(get($aside-config, minimized-width) - $card-box-shadow-size);
    }

    // Aside default enabled and aside fixed modes
    .aside-enabled.aside-fixed & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, width-content);
    }

    // Aside default enabled, aside fixed and aside minimize modes
    .aside-enabled.aside-fixed[data-kt-aside-minimize="on"] & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, minimized-width);
    }

    // Fixed header mode
    .header-fixed & {
      padding-top: calc(get($header-config, fixed, height, desktop) + get($header-config, fixed, top));
    }

    // Fixed header & fixed toolbar modes
    .header-fixed.toolbar-fixed & {
      padding-top: calc(#{get($header-config, fixed, height, desktop)} + var(--kt-toolbar-height));
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile);
  }

  // Wrapper
  .wrapper {
    // Fixed header mode
    .header-tablet-and-mobile-fixed & {
      padding-top: get($header-config, fixed, height, tablet-and-mobile);
    }

    // Fixed header & fixed toolbar modes
    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
      padding-top: calc(#{get($header-config, fixed, height, tablet-and-mobile)} + var(--kt-toolbar-height-tablet-and-mobile));
    }
  }
}
