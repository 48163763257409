//
// Text Sizes
//

// @formatter:off

h1 { @include h1 }
h2 { @include h2 }
h3 { @include h3 }
h4 { @include h4 }

.text-large-regular { @include text-large-regular }
.text-large-bold    { @include text-large-bold }
.text-body-regular  { @include text-body-regular }
.text-body-medium   { @include text-body-medium }
.text-body-bold     { @include text-body-bold }
.text-small-regular { @include text-small-regular }
.text-small-medium  { @include text-small-medium }

//
// Text Colors
//
.text-black         { color: black }
.text-white         { color: white }

.text-primary-100   { color: $primary-100 }
.text-primary-200   { color: $primary-200 }
.text-primary-300   { color: $primary-300 }
.text-primary-400   { color: $primary-400 }
.text-primary-500   { color: $primary-500 }
.text-primary-600   { color: $primary-600 }
.text-primary-700   { color: $primary-700 }
.text-primary       { color: $primary }

.text-secondary-100 { color: $secondary-100 }
.text-secondary-200 { color: $secondary-200 }
.text-secondary-300 { color: $secondary-300 }
.text-secondary-400 { color: $secondary-400 }
.text-secondary-500 { color: $secondary-500 }
.text-secondary-600 { color: $secondary-600 }
.text-secondary-700 { color: $secondary-700 }
.text-secondary     { color: $secondary }

.text-neutral-100   { color: $neutral-100 }
.text-neutral-200   { color: $neutral-200 }
.text-neutral-300   { color: $neutral-300 }
.text-neutral-400   { color: $neutral-400 }
.text-neutral-500   { color: $neutral-500 }
.text-neutral-600   { color: $neutral-600 }
.text-neutral-700   { color: $neutral-700 }
.text-neutral       { color: $neutral }

.text-danger-100    { color: $danger-100 }
.text-danger-200    { color: $danger-200 }
.text-danger-300    { color: $danger-300 }
.text-danger-400    { color: $danger-400 }
.text-danger-500    { color: $danger-500 }
.text-danger-600    { color: $danger-600 }
.text-danger-700    { color: $danger-700 }
.text-danger        { color: $danger }

.text-success-100   { color: $success-100 }
.text-success-200   { color: $success-200 }
.text-success-300   { color: $success-300 }
.text-success-400   { color: $success-400 }
.text-success-500   { color: $success-500 }
.text-success-600   { color: $success-600 }
.text-success-700   { color: $success-700 }
.text-success       { color: $success }

.text-info-100      { color: $info-100 }
.text-info-200      { color: $info-200 }
.text-info-300      { color: $info-300 }
.text-info-400      { color: $info-400 }
.text-info-500      { color: $info-500 }
.text-info-600      { color: $info-600 }
.text-info-700      { color: $info-700 }
.text-info          { color: $info }

.text-accent-violet { color: $accent-violet }
.text-accent-green  { color: $accent-green }
.text-accent-yellow { color: $accent-yellow }

p:last-child { margin-bottom: initial }

// @formatter:on