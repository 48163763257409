//
// Typography
//
@mixin h1 {
  font-size: 48px;
  font-weight: 800;
  line-height: 48px;
}

@mixin h2 {
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
}

@mixin h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
}

@mixin h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

@mixin text-large-regular {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@mixin text-large-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@mixin text-body-regular {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

@mixin text-body-medium {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

@mixin text-body-bold {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

@mixin p-margin-bottom-small {
  p:not(:last-child) {
    margin-bottom: 4px
  }
}

@mixin text-small-regular {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  @include p-margin-bottom-small;
}

@mixin text-small-medium {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;

  @include p-margin-bottom-small;
}
