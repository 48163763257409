//
// Toast & Alert
//
.toast,
.alert {
  @include text-body-regular;
  border: none;
  padding: 8px 16px 8px 8px;
  width: auto;

  @mixin toast-border-and-shadow($boder-color, $shadow-color) {
    box-shadow: inset 0 0 0 2px $boder-color,
    0 16px 24px -8px rgba($shadow-color, .24);
  }

  @mixin alert-border($boder-color) {
    box-shadow: inset 0 0 0 2px $boder-color;
  }

  @mixin btn-close-focus($color) {
    box-shadow: 0 0 0 4px rgba($color, .24);
  }

  &.toast-danger {
    @include toast-border-and-shadow($danger-300, #A70B2F);
  }

  &.alert-danger {
    @include alert-border($danger-300);
  }

  &.toast-danger,
  &.alert-danger {
    background-color: $danger-100;

    .toast-body,
    .alert-body {
      color: $danger-600;

      &::before {
        @include svg-bg-icon(error);
      }
    }

    .btn-close {
      @include svg-bg-icon(cross, $danger-300);

      &:hover,
      &:focus {
        @include svg-bg-icon(cross, $danger-500);
      }

      &:focus {
        @include btn-close-focus($danger-500);
      }
    }
  }

  &.toast-warning {
    @include toast-border-and-shadow($primary-500, #996C12);
  }

  &.alert-warning {
    @include alert-border($primary-500);
  }

  &.toast-warning,
  &.alert-warning {
    background-color: $primary-100;

    .toast-body,
    .alert-body {
      color: $primary-700;

      &::before {
        @include svg-bg-icon(warning);
      }
    }

    .btn-close {
      @include svg-bg-icon(cross, $primary-600);

      &:hover,
      &:focus {
        @include svg-bg-icon(cross, $primary-700);
      }

      &:focus {
        @include btn-close-focus($primary-500);
      }
    }
  }

  &.toast-success {
    @include toast-border-and-shadow($success-300, #358343);
  }

  &.alert-success {
    @include alert-border($success-300);
  }

  &.toast-success,
  &.alert-success {
    background-color: $success-100;

    .toast-body,
    .alert-body {
      color: $success-600;

      &::before {
        @include svg-bg-icon(success);
      }
    }

    .btn-close {
      @include svg-bg-icon(cross, $success-400);

      &:hover,
      &:focus {
        @include svg-bg-icon(cross, $success-600);
      }

      &:focus {
        @include btn-close-focus($success-500);
      }
    }
  }

  &.toast-info,
  &.toast-news {
    @include toast-border-and-shadow($neutral-400, #303033);
  }

  &.alert-default {
    @include alert-border($neutral-400);
  }

  &.toast-info,
  &.toast-news,
  &.alert-default {
    background-color: $neutral-200;

    .toast-body,
    .alert-body {
      color: $black;
    }

    .btn-close {
      @include svg-bg-icon(cross, $neutral-400);

      &:hover,
      &:focus {
        @include svg-bg-icon(cross, $neutral-600);
      }

      &:focus {
        @include btn-close-focus($neutral-500);
      }
    }
  }

  &.toast-info {
    .toast-body {
      &::before {
        @include svg-bg-icon(infoGray);
      }
    }
  }

  &.toast-news {
    .toast-body {
      &::before {
        @include svg-bg-icon(megaphone, $black);
      }
    }
  }

  &.alert-default {
    .alert-body {
      &::before {
        @include svg-bg-icon(infoGray);
      }
    }
  }

  &.toast-action {
    @include toast-border-and-shadow($info-400, #0050A6);
  }

  &.alert-info {
    @include alert-border($info-400);
  }

  &.toast-action,
  &.alert-info {
    background-color: $info-100;

    .toast-body,
    .alert-body {
      color: $info-700;
    }

    .toast-body {
      &::before {
        @include svg-bg-icon(arrowRight, $info);
      }
    }

    .alert-body {
      &::before {
        @include svg-bg-icon(infoBlue);
      }
    }

    .btn-close {
      @include svg-bg-icon(cross, $info-400);

      &:hover,
      &:focus {
        @include svg-bg-icon(cross, $info-600);
      }

      &:focus {
        @include btn-close-focus($info-500);
      }
    }
  }

  .toast-body,
  .alert-body {
    display: flex;
    padding: 2px 0 0;
    width: 100%;
      align-items: center;

    &::before {
      $size: 24px;
      content: '';
      background-size: 100%;
      display: block;
      flex-shrink: 0;
      height: $size;
      margin: 8px 8px 8px;
      width: $size;
    }
  }

  .btn-close {
    $size: 40px;
    @include svg-bg-icon(cross, $danger-300);
    flex-shrink: 0;
    height: $size;
    margin-right: -12px;
    opacity: 1;
    padding: 0;
    width: $size;
  }
}

.toast {
  max-width: $toast-max-width;
}

.alert {
  margin-bottom: initial;

  &.alert-sm {
    padding: 0px 8px 0 0;

    .alert-body {
      padding: 6px 4px;

      &::before {
        $size: 16px;
        height: $size;
        margin: 2px 8px 2px;
        width: $size;
      }
    }

    .btn-close {
      $size: 24px;
      height: $size;
      margin-top: 4px;
      margin-right: -4px;
      width: $size;
    }
  }
}

// TODO: autoclosable toast (timer indicator) styling
