//
// Datepicker
//
.input-group.date {
  $icon-size: 32px;
  display: inline-flex;
  position: relative;
  width: 100%;

  .input-group-prepend,
  .input-group-append {
    background-color: transparent;
    bottom: 0;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;

    .kv-date-picker {
      @include svg-bg-icon(calendar);
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      bottom: 0;
      cursor: pointer;
      height: 40px;
      pointer-events: all;
      position: absolute;
      right: 0;
      width: $icon-size;
    }
  }

  .krajee-datepicker {
    border-radius: $border-radius !important;
    height: initial;
    padding-right: $icon-size;
  }
}

.datepicker.dropdown-menu {
  border-radius: $border-radius-lg;
  padding: 16px 24px;

  &::before,
  &::after {
    display: none;
  }

  thead,
  tbody {
    display: flex;
    flex-direction: column;
  }

  thead {
    border-bottom: 1px solid $neutral-200;
    margin-bottom: 8px;

    tr:nth-child(2) {
      padding-bottom: 8px;
    }
  }

  tr,
  th,
  td {
    align-items: center;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  & > div:not(.datepicker-days) td {
    display: table-row-group;
    height: auto;
    width: auto;
  }

  th.dow {
    @include text-small-regular;
    color: $neutral-500;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    text-transform: uppercase;
  }

  .datepicker-days tbody td {
    $size: 32px;
    border-radius: 50%;
    height: $size;
    margin: 2px 4px;
    padding-top: 1px;
    padding-left: 1px;
    width: $size;

    &:hover,
    &:focus {
      background-color: transparent !important;
      box-shadow: inset 0 0 0 2px $neutral-300;
    }
  }

  .old,
  .new {
    color: $neutral-300;
  }

  .day {
    &:nth-child(n+6),
    &.highlighted {
      background-color: transparent;
      border-color: transparent;
      border-radius: 50%;
      color: $danger-500;

      &.old,
      &.new {
        color: $danger-200;
      }
    }

    &.today {
      background-color: transparent;
      position: relative;

      &::after {
        $size: 4px;
        content: '';
        background-color: $accent-violet;
        border-radius: 50%;
        bottom: 3px;
        height: $size;
        left: 50%;
        margin-left: calc($size / -2);
        position: absolute;
        width: $size;
      }
    }

    &.active {
      &,
      &:hover,
      &:focus {
        background-color: $primary-300 !important;
        box-shadow: none;
        color: $black;
        text-shadow: none;
      }
    }
  }

  .datepicker-switch {
    @include text-large-regular;
    justify-content: flex-start;
    margin-left: -8px;
    margin-right: auto;
    order: -1;
    padding-right: 8px;
    width: auto;

    &:hover,
    &:focus {
      background-color: $neutral-100;
    }

    &::before {
      $size: 16px;
      @include svg-bg-icon(caretLeft, $neutral-400);
      content: '';
      display: inline-block;
      height: $size;
      margin-right: 4px;
      width: $size;
    }
  }

  .datepicker-centuries .datepicker-switch {
    margin-left: 4px;
    padding-left: 8px;
    pointer-events: none;

    &::before {
      display: none;
    }
  }

  th.prev,
  th.next {
    color: transparent;
    background-repeat: no-repeat;

    &:hover,
    &:focus {
      background-color: $neutral-100;
    }
  }

  th.prev {
    @include svg-bg-icon(angleLeft);
    background-position: calc(50% - 1px) center;
  }

  th.next {
    @include svg-bg-icon(angleRight);
    background-position: calc(50% + 1px) center;
  }

  .datepicker-months,
  .datepicker-years,
  .datepicker-decades,
  .datepicker-centuries {
    td {
      width: 280px !important;
    }
  }

  .month,
  .year,
  .decade,
  .century {
    @include text-body-regular;
    align-items: center;
    border-radius: $border-radius-lg;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 2px;

    &:hover,
    &:focus {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $neutral-300;
    }

    &.focused {
      background-color: $primary-300 !important;
      box-shadow: none !important;
      color: inherit !important;
    }
  }

  .month {
    $margin-x: 24px;
    margin: 8px 0;
    width: calc(100% / 3 - 16px);

    &:nth-child(3n-1) {
      margin-left: $margin-x;
      margin-right: $margin-x;
    }
  }

  .year {
    margin: 8px 2px;
    width: calc(100% / 6 - 4px);
  }

  tfoot {
    display: flex;
    justify-content: flex-end;
  }

  .clear {
    @extend .btn, .btn-text;
    height: auto;
    margin-bottom: -8px;
    width: auto;
  }
}
