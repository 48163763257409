.documents-distribution {
    display: flex;
    margin-top: 32px;
    align-items: flex-start;

    &__column-menu {
        flex: 0 0 224px;
        margin-right: 16px;
    }

    &__column-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}
