.vertical-menu {
  ul {
    padding: 0.5rem 0.5rem 0.2rem 0.5rem;
    list-style-position: inside;
    margin-bottom: 5px;

    li {
      position: relative;
      padding: 8px;
      display: flex;
      align-items: center;

      a {
        color: black;
        font-size: 15px;
        font-weight: 400;
        margin-left: 20px;
        word-wrap: anywhere;
      }

      .dashed {
        display: block;
        position: absolute;
        width: 1px;
        height: 25px;
        border-left: 2px dashed var(--bs-gray-300);
        top: 30px;
        left: 13px;
      }

      &:last-child .dashed {
        border-left-color: transparent;
      }

      &.new::before {
        border-radius: 0.5rem;
        border: solid 2px #FF4E65;
        width: 0.5rem;
        height: 0.5rem;
        display: block;
        font-size: 0;
        color: #FFEBEB;
        margin-left: 2px;
      }

      &::before {
        content: '\2022';
        color: var(--bs-gray-300);
        font-size: 2em;
        margin-right: 8px;
        position: absolute;
      }

      &:after {
        top: 0;
      }
    }

    .active {
      background: var(--bs-gray-100);
      border-radius: 8px;
    }
  }
}
