.grid-view-common-yii-panel__custom-options {
  margin-left: 0px !important;
}

.card-block-gray {
  background-color: #F0F0F1;
  border-radius: 8px
}

.search-block {
  position: relative;
}

.search-icon {
  position: absolute;
  left: 13px;
  top: 9px;
  color: #595A5F;
}

.filter-input-territories {
  padding-left: 35px;
}

.table-setting-app {
  top: 5px;
}
