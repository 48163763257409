// NoUiSlider success color option
.noUi-target.noUi-target-success {
  .noUi-connects {
    background: $success-light;

    .noUi-connect {
      background: $success;
    }
  }

  .noUi-handle {
    border: 1px solid $success;
    box-shadow: 0 3px 6px -3px rgba($success, 0.7);
    background: $success;
  }
}