.notification-confirm-modal {
  display: flex;
  flex-direction: column;

  &__image {
    margin-bottom: 18px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__file-name {
    @include text-small-medium;
    margin-bottom: 24px;
  }

  &__content {
    @include text-body-regular;
    text-align: left;
  }
}
