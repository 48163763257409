.order-property-item {
  display: flex;

  &__col-icon {
    width: 24px;
    margin-right: 24px;
    flex-grow: 0;
  }

  &__col-content {
    flex-grow: 1;
  }

  &__title {
    @include text-large-bold;
  }
}