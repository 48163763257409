.order-table {
  &__action-column {
    width: 164.67px + 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.table {
  &__order-amount {
    text-align: right;
  }
}