.basket-related-items {

  display: flex;
  padding: 16px 0px;

  &__image {
    height: 96px;
    width: 96px;
    margin-right: 7px;
  }

  &__arrow-icon {
    width: 15px;
  }

  &__image-wrapper {
    text-align: center;
    height: 96px;
    width: 96px;
    margin-right: 7px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    flex-grow: 1;

    @include text-body-regular;
  }

  &__content-params {
    padding-top: 8px;
  }

  &__content-params-icons {
    display: none;
    padding-top: 8px;
  }

  &__content-params-icons > div > img {
    margin-right: 4px;
  }

  &__sim-card-params {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ul li {font-size: 0.9em;}
  }

  &__head {
    display: flex;
    @include text-small-regular;
    color: $gray-500;
  }

  &__content-name {
    flex-grow: 1;
  }

  &__content-description {
    margin-left: 40px;
    display: flex;
    gap: 8px;
    color: $gray-500;
  }

  &__content-description-price {
    color: #212529;
  }

  &__price-and-count {
    color: #B6B7B9;
    min-width: 72px;
  }

  &__price {
    white-space: nowrap;
    min-width: 100px;
    padding-left: 8px;
  }

  &__price-total {
    @include text-body-medium;
    color: $gray-500;
  }

  &__price-unit {
    @include text-small-regular;
    color: $gray-500;
  }

  &__count {
    //@extend .form-control;
    display: flex;
    width: 72px;
    height: 40px;
    text-align: center;
    margin-left: 40px;
    gap: 10px;
    color: #B6B7B9;
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}


#basket_items.items_short .basket-related-items__image,
#basket_items.items_short .basket-related-items__image-wrapper,
#basket_items.items_short .basket-related-items__sim-card-params {
  display: none;
}

#basket_items.items_short .basket-related-items__content-params-icons {
  display: flex;
}
