.card-not-found {
  @extend .card, .card-body;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  text-align: center;
}

.card-not-found__title {
  @include text-large-bold;
  margin-top: 24px;
}

.card-not-found__detail {
  @include text-body-regular;
  margin-top: 8px;
}
