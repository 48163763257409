.mechanics-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 40px 32px;
  gap: 8px;
}

.mechanics-body div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 8px;
  width: 250px;
  height: 72px;
  background: $gray-100;
  border-radius: 12px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
