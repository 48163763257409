.pay-account-grid__item, .main-grid__item-pay {
  width: 255px;
  min-height: 188px;
  color: inherit !important;
  cursor: auto;
  outline: none;
}

.svg-icon-new-item-white {
  width: 40px !important;
  height: 40px !important;
}

.pay-account-grid__item-name {
  padding: 0px !important;
  font-size: 16px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.territories-text-header-size {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}


.badge.badge-default {
  box-shadow: none !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.svg-icon-sort-asc-main {
  transform: rotate(180deg);
}

.left-padding {
  left: -6px;
}

.inner-shadow::before,
.inner-shadow::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px; /* Ширина градиента */
  pointer-events: none; /* Позволяет клики проходить через псевдоэлементы */
}

.inner-shadow::before {
  left: 0;
  border-radius: 16px;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.inner-shadow::after {
  right: 0;
  border-radius: 16px;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.select2-search__field::placeholder {
  color: #B6B7B9;
  font-size: 15px;
  line-height: 20px;
}

.pay-account {
  &__item {
    color: inherit !important;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
      .staff-grid__item-name::after {
        opacity: 1;
      }
    }
  }

  &__item-name {
    @include text-body-medium;
    padding-right: 40px;
    position: relative;

    &:hover::after {
      @include svg-bg-icon(pencil, $info-500);
      background-color: $info-200;
    }
  }
}
