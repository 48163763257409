.basket-index-page {

  &__top {
    padding-bottom: 24px;
  }

  &__content {
    display: flex;
    gap: 24px;
  }

  &__left-column {
    flex-grow: 1;
  }

  &__right-column {
    width: 320px;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.summary-widget-app>div {
  margin-bottom: 24px;
}