.card-oder {
  @extend .card;

  @mixin paddingMixin {
    padding: 16px;
  }

  &__header {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $border-color;

    @include paddingMixin;
  }

  &__body {
    @include paddingMixin;
  }

  &__header-text {
    margin-top: 8px;

    color: $gray-500;
    @include text-small-regular;
  }

  &__amount-detail {
    @include text-small-regular;
    display: flex;
  }

  &__amount-total {
    margin-top: 4px;

    @include text-large-bold;
    display: flex;
    justify-content: space-between;
  }
}