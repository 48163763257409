.dealer-code-list {
  height: 220px;
  padding: 0px;
  margin: 0px;

  &__li {
    position: relative;
    display: block;
    list-style-type: none;

    &_active {
      &::before {
        position: absolute;
        top: 12px;
        left: 0px;
        content: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.9962 0.437631C13.3068 0.711683 13.3364 1.18563 13.0624 1.49623L5.56238 9.99622C5.4254 10.1515 5.23034 10.2432 5.0234 10.2496C4.81647 10.2561 4.61607 10.1767 4.46967 10.0303L0.96967 6.53034C0.676777 6.23745 0.676777 5.76257 0.96967 5.46968C1.26256 5.17679 1.73744 5.17679 2.03033 5.46968L4.96582 8.40517L11.9376 0.503793C12.2117 0.1932 12.6856 0.163578 12.9962 0.437631Z' fill='%23499857'/%3E%3C/svg%3E%0A");
      }
    }

    &:hover .dealer-item__first-line {
      color: #F5A207;
    }
  }

  &__item {
    display: block;
    padding: 10px 10px 10px 30px;
    color: #000000;
  }

  // TODO Scroll
}