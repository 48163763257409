.sim-card-number-mask {

  margin-left: 3px;
  margin-right: 3px;

  &__add {
    margin-top: 24px;

    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  &__select {
    flex-grow: 1;
    max-width: 230px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__mr-8 {
    margin-right: 8px;
  }

  &__mask-icon {
    width: 12px;
    height: 12px;
    margin-top: 2px;
  }
}
