.basket-summary-vue {
  position: fixed;
  bottom: 24px;
  display: flex;
  gap: 8px;
  border-radius: 16px;
  background: $accent-violet;
  box-shadow: 0px -4px 16px -8px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.16);
  padding: 8px 32px 8px 16px;

  color: #FFFFFF;
  z-index: 100;
  transform: translateX(-100%);

  &__price-value {
    @include text-large-bold;
  }

  &__price-sign {
    @include text-body-regular;
  }

  &__amount {
    @include text-small-regular;
  }

  &__empty {
    @include text-large-regular;
    padding: 5px;
  }
}

.basket-summary-link {
  position: absolute;
  right: 0;
}