//
// Spinner
//
.spinner-border {
  $size: 20px;
  border-width: 2.5px;
  color: $primary-300;
  flex-shrink: 0;
  height: $size;
  width: $size;
}
