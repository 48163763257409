.distribution-filter {
    padding: 8px;
}

.distribution-filter .date-filter {
    margin-bottom: 16px;
}

.distribution-filter .documents-menu__item {
    padding: 8px 0;
}

.distribution-filter .documents-menu__item.hidden {
    display: none;
}

.distribution-filter .documents-menu {
    max-height: 570px;
}

.distribution-filter .documents-menu a:hover {
    color: #F5A207;
}

.file-list a.file:hover .file__name {
    color: $info-400;
}

.file-list a.file:visited .file__icon-image {
    color: $gray-400;
}

.distribution-content.card .card-body {
    padding: 0 24px 16px 24px;
}

.file__name {
  @include text-body-regular;
  flex: none;
  flex-grow: 1;
  margin-bottom: 4px;
  color: $black;

  word-wrap: break-word;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  &:hover {
    color: $info-400;
  }
}
