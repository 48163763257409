.products-index-page-table {

  &__first-column {
    @extend .grid-view-common-yii__sticky-col;

    width: 60px;
    left: 0px;
  }

  &__column-name {
    min-width: 400px;
  }

  &__count-input {
    max-width: 50px;
    margin: auto;
    padding: 8px;
  }
}