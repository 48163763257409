//
// Pagination
//
.pagination {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0;
}

.page-periods {
  @include text-body-regular;
  padding: 6px 4px;
}

.page-periods,
.page-item {
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }

  &:hover:not(.active, .no-shadow) {
    .page-link {
      box-shadow: inset 0 0 0 2px $neutral-300;
    }
  }

  &.active {
    .page-link {
      background-color: $primary-300;
      box-shadow: none;
      cursor: default;
    }
  }

  .page-link {
    border-radius: calc($pagination-item-height / 2) !important;
    height: $pagination-item-height;
    font-size: $pagination-font-size;
    font-weight: $pagination-font-weight;
    min-width: $pagination-item-height;
    padding: 7px 8px;
    text-align: center;
    transition: none;
  }
}
