.financial-info {
  &__loader {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 8px;
    border-radius: 8px;
    background: #F0F0F1;
    font-size: 15px;
  }
  &__loader div {display: flex;}


  &__data {
    display: flex;
    gap: 8px;
    font-size: 15px;
  }

  &__data > div {display: flex; align-items: center; padding: 6px 8px; border-radius: 8px; background: #F0F0F1;}

  &__panel1, &__panel2, &__panel3 { gap: 8px; }

  &__panel1 > div, &__panel2 > div, &__panel3 > div {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
}

