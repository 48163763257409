//
// Table
//
.table {
  color: $body-color;
  line-height: initial;

  &.align-middle {
    th,
    td {
      vertical-align: inherit !important;
    }
  }

  thead {
    vertical-align: inherit;

    th {
      @include text-small-medium;
      border-top: none;
      border-bottom: 1px solid $neutral-200;
      font-weight: $font-weight-bold;
      vertical-align: inherit;

      &.w-25px {
        padding-bottom: 9px;
      }

      &:not(.w-25px) {
        padding-top: 13px;
      }

      sort,
      filter {
        $size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        display: inline-block;
        margin-left: 4px;
        margin-bottom: -4px;
        height: $size;
        width: $size;
      }

      sort {
        @include svg-bg-icon(verticalArrows, $neutral-400);

        &.asc {
          @include svg-bg-icon(sortAsc, $black);
        }

        &.desc {
          @include svg-bg-icon(sortDesc, $black);
        }
      }

      filter {
        @include svg-bg-icon(filter, $neutral-300);

        &.active {
          @include svg-bg-icon(filter, $primary-500);
        }
      }
    }
  }

  tbody {
    border-top: none !important;

    td {
      @include text-body-regular;
      vertical-align: inherit;
    }
  }
}
