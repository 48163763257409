.dealer-item {
  &__title {
    @include text-body-medium;
  }

  &__hint {
    @include text-small-regular;
    color: $gray-500 !important;
  }
}

.dealer-item__first-line:hover .vertical-separator::before {
    color: #F5A207;
}
