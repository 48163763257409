.table th {
  text-wrap: nowrap;
}

.table-hover {
  --bs-table-hover-bg: #D6E9FF;
}

.nowrap thead th {
  white-space: nowrap;
}

.grid-view tr:has(.table-row-deleted) {
  background: $warning-active;
}

.grid-view tbody {
  tr.any-item-as-link {
    &:hover {
      cursor: pointer;
      background-color: $secondary-100;
    }
  }
}

.grid-view-common-yii {
  &__sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background: rgba(255, 255, 255, 1) !important;
  }
}

.margin-left-10 {
  margin-left: 10px;
}

.grid-button-position-right {
  position: absolute;
  right: 20px;
  top: 5px
}
