// Карточка выбора бординга и выбора партнера
ul.choice-contract-list {
  display: block;
  overflow-y: scroll;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    display: block;
    list-style-type: none;

    a {
      display: flex;
      padding: 10px 10px 10px 5px;
      color: #000000;
      align-items: center;
    }

    a:hover span.title {
      color: #F5A207;
    }

    .title {
      display: block;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }

    .hint {
      display: block;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #595A5F;
      line-height: 16px;
    }

    .content {
      padding: 0;
    }

    .angle {
      height: 16px;
      width: 16px;
      margin-left: auto;
    }
  }

  li:not(:last-child) {
    box-shadow: 0 1px 0 0 #E1E2E6;
  }
}
//Обрезаем кота по макету
.cat-in-title-container {
    width: 6.5rem;
    height: 6.5rem;
    position: absolute;
    top: 0;
    right: 0;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 7px -15px;
        border-top-right-radius: 16px;
    }
}
