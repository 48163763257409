//
// Side Errors
//
.side-errors {
  &__header {
    @include text-large-bold;
    align-items: flex-start;
    display: block;

    &::before {
      $size: 24px;
      @include svg-bg-icon(error);
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      flex-shrink: 0;
      height: $size;
      margin-right: 8px;
      width: $size;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 32px;
  }

  &__item {
    @include text-body-regular;
    margin-top: 16px;

    &::before {
      $size: 6px;
      content: '';
      background-color: $neutral-300;
      border-radius: 50%;
      display: inline-block;
      height: $size;
      margin-left: -23px;
      margin-right: 17px;
      width: $size;
    }
  }
}
