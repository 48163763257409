.preorder-processing-detail-items {
  @extend .card;
  margin-top: 24px;

  &__head {
    padding: 16px 24px;

    display: flex;
    justify-content: space-between;
  }

  &__head-left-column {
    display: flex;
    align-items: center;
  }

  &__head-title {
    @include text-body-medium;
    margin-right: 16px;
    margin-left: 28px;
  }

  &__head-info {
    display: flex;
    @include text-small-regular;
    align-items: center;
    gap: 5px;
  }

  &__head-info-price {
    @include text-body-medium;
  }

  &__body {
    display: flex;
    flex-direction: column;

  }



  /* new style */
  //&__position {
  //  display: flex;
  //}

}