.notification-catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  justify-content: center;
  text-align: center;

  &__image {
    height: 192px;
    width: 192px;
  }

  &__title {
    margin-top: 24px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  &__detail {
    @include text-body-regular;
    text-align: center;
  }

  &__button {

    margin-top: -8px;
  }
}
