//
// Nav
//

// Line tabs
.nav-line-tabs {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $border-color;

  .nav-item {
    margin-bottom: -1px;

    // Base link
    .nav-link {
      color: $gray-500;
      border: 0;
      border-bottom: 1px solid transparent;
      transition: $transition-link;
      padding: 0.5rem 0;
      margin: 0 1rem;
    }

    // First Item
    &:first-child {
      .nav-link {
        margin-left: 0;
      }
    }

    // Last Item
    &:last-child {
      .nav-link {
        margin-right: 0;
      }
    }
  }

  // Active & Hover States
  .nav-item .nav-link.active,
  .nav-item.show .nav-link,
  .nav-item .nav-link:hover:not(.disabled) {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $primary;
    transition: $transition-link;
  }

  // 2x Line
  &.nav-line-tabs-2x {
    border-bottom-width: 2px;

    .nav-item {
      margin-bottom: -2px;

      .nav-link {
        border-bottom-width: 2px;
      }
    }

    // Active & Hover States
    .nav-item .nav-link.active,
    .nav-item.show .nav-link,
    .nav-item .nav-link:hover:not(.disabled) {
      border-bottom-width: 2px;
    }
  }
}

// Nav Pills
.nav-pills {
  .nav-item {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

// Stretch items
.nav-stretch {
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .nav-item {
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .nav-link {
    display: flex;
    align-items: center;
  }
}

// Nav group
.nav-group {
  padding: 0.35rem;
  @include border-radius($border-radius);
  background-color: $gray-100;

  // Outline nav group
  &.nav-group-outline {
    background-color: transparent;
    border: 1px solid $border-color;
  }

  // Fluid option
  &.nav-group-fluid {
    display: flex;

    > label,
    > .btn {
      position: relative;
      flex-shrink: 0;
      flex-grow: 1;
      flex-basis: 0;
    }

    > label {
      margin-right: 0.1rem;

      > .btn {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}