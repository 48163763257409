.territory-menu {
  @extend .card;
  padding: 8px;
  max-height: calc(100vh - 162px);
  display: flex;
  overflow: scroll;
  flex-direction: column;
}

.territory-menu__item {
  display: flex;
  align-items: flex-start;
  padding: 8px;
  cursor: pointer;
}

.territory-menu__item_current {
  background: $gray-100;
  border-radius: 8px;
}

.territory-menu__item-name {
  @include text-body-regular;
  color: black;
  transition: color .2s ease-in-out;
}

.territory-menu__item:hover:not(.territory-menu__item_current) .territory-menu__item-name {
  color: $primary-500;
}

.territory-menu__item-hint {
  @include text-small-regular;
  color: $gray-500;
}

.territory-menu__item-marker {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 6px;
  width: 8px;
  height: 8px;
  background: $gray-300;
  border-radius: 8px;
  flex-shrink: 0;
}