.delivery-index-page {
  &__content {
    display: flex;
    gap: 24px;
  }

  &__left-column {
    flex-grow: 1;

    &__card {
      margin-bottom: 24px;
      padding: 16px 24px;
    }
  }

  &__right-column {
    width: 320px;
    display: flex;
    flex-direction: column;
  }
}