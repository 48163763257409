// Aside menu
.aside-menu {
  $padding-x: 24px;

  &,
  & * {
    user-select: none;
  }

  @mixin angleDown($size) {
    @include svg-bg-icon(angleDown, $neutral-400);
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    flex-shrink: 0;
    height: $size;
    margin-left: 4px;
    transition: .2s ease-in;
    width: $size;
  }

  .menu-section {
    &.show {
      .menu-section__title::after {
        background-position: center calc(50% - 2px);
        transform: rotate(180deg);
      }
    }

    &.active {
      .menu-section__title {
        cursor: default;

        &::after {
          display: none;
        }
      }
    }

    &__title {
      @include text-body-medium;
      align-items: flex-start;
      cursor: pointer;
      display: flex;
      padding: 16px $padding-x 8px;

      &::after {
        @include angleDown(16px);
        background-position: center calc(50% + 3px);
      }
    }
  }

  .menu-item {
    border-radius: $border-radius;
    margin: 2px $padding-x;
    padding: 0;

      // иконки добавлять сюда, названия должны соответствовать указанным в assets/app/theme/src/sass/components/mixins/_svg-bg-icon.scss
      $icons: users,
      chart,
      rbac,
      rbacUsers,
      building,
      location,
      catalog,
      preorders,
      orders,
      products,
      certificate,
      invoices,
      settings,
      mail,
      errors,
      cloud,
      reference,
      partners,
      finance,
      learning,
      offers,
      instructions,
      auth;

    @mixin icon($color) {
      @each $icon in $icons {
        .menu-icon_#{$icon} {
          @include svg-bg-icon(#{$icon}, $color);
        }
      }
    }

    &.menu-accordion {
      transition: box-shadow get($aside-config, transition-speed) ease;

      &.showing,
      &.show {
        &:not(.hiding) {
          box-shadow: -1px -1px 2px rgba(white, .88),
          -4px -4px 12px rgba(white, .88),
          1px 1px 4px rgba(170, 175, 185, .24),
          2px 2px 4px rgba(170, 175, 185, .16),
          8px 8px 8px rgba(170, 175, 185, .08),
          inset 2px 2px 4px rgba(white, .24);
          transition-duration: .2s;
        }

        & > .menu-link::after {
          background-position: center center;
          transform: rotate(180deg);
        }
      }

      & > .menu-link::after {
        @include angleDown(24px);
        background-position: center calc(50% + 1px);
      }
    }

    .menu-link {
      @include text-body-regular;
      border-radius: 12px;
      color: $body-color;
      padding: 12px;

      &:not(.active):hover,
      &:not(.active):focus {
        color: $primary-600;

        @include icon($primary-500);
      }

      &.active {
        $item-bg-color: $primary-200;
        background-color: $item-bg-color;
        box-shadow: inset 0 0 0 2px $primary-400;

        @include icon($primary-700);

        .menu-badge {
          box-shadow: 0 0 0 2px $item-bg-color;
        }
      }
    }

    .menu-icon {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;
      height: 24px;
      margin-right: get($menu, link, icon, space);
    }

    @include icon($neutral-300);

    .menu-title {
      padding: 2px 0;
    }

    .menu-badge {
      background-color: $danger-400;
      border-radius: 8px;
      box-shadow: 0 0 0 2px $page-bg;
      color: $white;
      flex-shrink: 0;
      font-size: 12px;
      font-weight: $font-weight-medium;
      line-height: 1;
      margin-top: 4px;
      min-width: 20px;
      padding: 3px 6px 1px;
      text-align: center;
    }
  }

  .menu-sub .menu-item .menu-link.active::before {
    @include svg-bg-icon(fourPointedStar, $primary-700);
  }

  .menu-sub {
    .menu-item {
      margin: 0 12px;

      .menu-link {
        padding-left: 8px;
        padding-right: 8px;

        &::before {
          $size: 24px;
          @include svg-bg-icon(fourPointedStar, $neutral-300);
          content: '';
          background-position: center;
          background-repeat: no-repeat;
          background-size: $size;
          display: block;
          flex-shrink: 0;
          height: $size;
          margin-right: 4px;
          width: $size;
        }
      }
    }
  }
}

[data-kt-aside-minimize=on] {
  .aside:not(:hover) {
    $active-width: 40px;
    $shrinking-padding: 4px;

    .menu-section__title {
      color: transparent;
      height: 32px;
      padding: 15px 24px;

      &::before {
        content: '';
        background-color: $neutral-200;
        border-radius: 1px;
        display: block;
        height: 2px;
        left: auto;
        margin: 0 $shrinking-padding;
        position: absolute;
        top: auto;
        width: $active-width;
      }

      &::after {
        display: none;
      }
    }

    .menu-accordion {
      box-shadow: none !important;
      transition-duration: 0 !important;
    }

    .menu-item {
      padding: $shrinking-padding;
    }

    .menu-link {
      max-width: $active-width;
      padding: 8px;
      position: relative;

      &::after {
        display: none;
      }
    }

    .menu-icon {
      margin-right: 0;
    }

    .menu-title {
      max-width: 0;
    }

    .menu-badge {
      margin-top: 0;
      position: absolute;
      right: -1px;
      top: 0;
    }

    .partnerka-logo {
      display: none;

      &_small {
        display: block;
      }
    }

  }
}

.partnerka-logo {
  display: block;

  &_small {
    display: none;
  }
}

.menu-sub > a {
  color: $black;
}

.menu-sub-dropdown .menu-item .justify-content-between {
  max-width: 325px;
}

.menu-sub-dropdown {
  .menu-item {
    align-items: center;
    background-color: $neutral-100;
    border-radius: $border-radius;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 16px 12px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:hover,
    &:focus {
      background-color: $neutral-200;
    }

    &::after {
      $size: 16px;
      @include svg-bg-icon(angleRight, $neutral-400);
      content: '';
      display: block;
      height: $size;
      width: $size;
    }

    &-exit::after {
      @include svg-bg-icon(signOut);
    }

    * {
      color: $black;
    }
  }
}

.aside-menu .menu-section:last-child .menu-item:last-child {
  margin-bottom: 18px;
}

.menu-item .user-profile-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-item .user-profile-item .profile-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.menu-item .user-profile-item .go-back-item {
  padding: 10px 0px 0px;
  justify-content: center;
  text-align: center;
}

.menu-item .user-profile-item .go-back-item a {
  color:#007AFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.menu-item .user-profile-item .go-back-item .icon-container {
  position: relative;
}

.menu-item .user-profile-item .go-back-item .icon-container img {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 6px;
  top: -4px;
}
