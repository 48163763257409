.toggle-button {
  height: 28px;
  width: 32px;
  border-radius: 6px;
  background-color: $secondary-100;
  color: #B6B7B9;
  cursor: not-allowed;

  display: flex;
  align-items: center;
  justify-content: center;

  &_active {
    color: #007AFF;
    background-color: $info-200;
    cursor: pointer;
  }
}