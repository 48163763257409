.order-detail-item {
  $order-detail-item-class: &;

  display: flex;
  padding: 16px 0px;

  &:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $border-color;
  }

  &__content {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    flex-grow: 1;

    @include text-body-regular;
  }

  &__head {
    display: flex;
    @include text-small-regular;
    color: $gray-500;
  }

  &__content-name {
    flex-grow: 1;
  }

  &__price-and-count {
    margin-left: 16px;

    display: flex;
    gap: 8px;
  }

  &__price {
    white-space: nowrap;
  }

  &__price-total {
    @include text-body-medium;
  }

  &__price-unit {
    @include text-small-regular;
    color: $gray-500;
  }
}