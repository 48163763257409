//
//  Tooltip
//
.tooltip {
  .tooltip-inner {
    backdrop-filter: blur(1.5px);
    box-shadow: $tooltip-box-shadow;
  }

  &.tooltop-auto-width {
    .tooltip-inner {
      white-space: nowrap;
      max-width: none;
    }
  }
}

$tooltip-margin: 3px;

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^=top] {
  margin-bottom: $tooltip-margin !important;

  .tooltip-arrow::before {
    top: 0;
  }
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^=bottom] {
  margin-top: $tooltip-margin !important;

  .tooltip-arrow::before {
    bottom: 0;
  }
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^=left] {
  margin-right: $tooltip-margin !important;

  .tooltip-arrow::before {
    left: 0;
  }
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^=right] {
  margin-left: $tooltip-margin !important;

  .tooltip-arrow::before {
    right: 0;
  }
}
