//
// Form Control
//
.form-control {
    @include text-body-regular;
    box-shadow: none !important;
    height: initial;

    &:hover:not(:disabled):not(:focus):not(:invalid):not(.is-invalid):not(:focus-within) {
        box-shadow: inset 0 0 0 1px $neutral-300 !important;
    }

    &:focus, &:focus-within {
        box-shadow: inset 0 0 0 2px $primary-400 !important;
    }

    &::placeholder {
        color: $neutral-300;
    }

    &:disabled {
        color: $neutral-300;

        &::placeholder {
            color: $neutral-200;
        }
    }

    &:not(:focus) {
        &:invalid,
        &.is-invalid {
            box-shadow: inset 0 0 0 2px $danger-300 !important;
        }
    }

    &.is-valid {
        @include svg-bg-icon(check, $success-300);
    }
}

textarea.form-control {
    min-height: 104px;
}

// Textarea reset resize
.resize-none {
    resize: none;
}
