//
// Global variables for Bootstrap customization.
// To override any Bootstrap variable safely you can use _variables.demo.scss
//

// @formatter:off


// Bootstrap base colors
$white:    										   	#ffffff !default;
$black:    											  #000000 !default;

$gray-100: 										    if(isDarkMode(), #1b1b29, #F0F0F1) !default;
$gray-200: 										    if(isDarkMode(), #2B2B40, #E1E2E6) !default;
$gray-300: 										    if(isDarkMode(), #323248, #B6B7B9) !default;
$gray-400: 										    if(isDarkMode(), #474761, #7C7D81) !default;
$gray-500: 										    if(isDarkMode(), #565674, #595A5F) !default;
$gray-600: 										    if(isDarkMode(), #6D6D80, #3B3C3F) !default;
$gray-700: 										    if(isDarkMode(), #92929F, #232325) !default;
$gray-800: 										    if(isDarkMode(), #CDCDDE, #232325) !default; // same as 700
$gray-900: 										    if(isDarkMode(), #FFFFFF, #232325) !default; // same as 700

$primary-100:   #FCF6E8;
$primary-200:   #FCEAB6;
$primary-300:   #FFCD69;
$primary-400:   #FFBB3D;
$primary-500:   #F5A207;
$primary-600:   #B87407;
$primary-700:   #593C04;
$primary:       $primary-400;

$secondary-100: #FAF7F5;
$secondary-200: #EDE1D5;
$secondary-300: #BAA38D;
$secondary-400: #57412D;
$secondary-500: #463525;
$secondary-600: #32261A;
$secondary-700: #18120D;
$secondary:     $secondary-400;

$neutral-100:   $gray-100;
$neutral-200:   $gray-200;
$neutral-300:   $gray-300;
$neutral-400:   $gray-400;
$neutral-500:   $gray-500;
$neutral-600:   $gray-600;
$neutral-700:   $gray-700;
$neutral:       $neutral-400;

$danger-100:    #FFEBEB;
$danger-200:    #FFADAE;
$danger-300:    #FF4E65;
$danger-400:    #E60A3C;
$danger-500:    #C0083A;
$danger-600:    #900632;
$danger-700:    #650427;
$danger:        $danger-400;

$success-100:   #EDF6EF;
$success-200:   #A8D3AF;
$success-300:   #62B171;
$success-400:   #499857;
$success-500:   #358343;
$success-600:   #1F4C26;
$success-700:   #122C17;
$success:       $success-400;

$info-100:      #EBF4FF;
$info-200:      #C2DFFF;
$info-300:      #85BFFF;
$info-400:      #007AFF;
$info-500:      #007AFF;
$info-600:      #004EA3;
$info-700:      #003166;
$info:          $info-200;

$text-muted:    $gray-500 !default;

$utility-link:  $info-400;

$accent-violet: #5D26FA;
$accent-green:  #69D9BE;
$accent-yellow: #FBE3B4;

// Dark Mode
$dark-mode-page-bg-color: #151521 !default;

// Bootstrap gray colors map
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
) !default; // Custom variable

// Bootstrap custom colors
$blue:                                             #009ef6 !default;
$indigo:                                           #6610f2 !default;
$purple:                                           #6f42c1 !default;
$pink:                                             #d63384 !default;
$red:                                              #dc3545 !default;
$orange:                                           #fd7e14 !default;
$yellow:                                           #ffc107 !default;
$green:                                            #198754 !default;
$teal:                                             #20c997 !default;
$cyan:                                             #0dcaf0 !default;

// Bootstrap colors map
$colors: (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
) !default;


// Bootstrap contextual colors
// Dark colors
$dark:          									  $gray-900 !default; // Bootstrap variable
$dark-active:          						  if(isDarkMode(), darken($gray-900, 9%), darken($gray-900, 3%)) !default;
$dark-light:          							$gray-200 !default; // Custom variable
$dark-inverse:         						  if(isDarkMode(), $gray-100, $white) !default; // Custom variable

// Primary colors
$primary-active:    								$primary-300;
$primary-light:    								  if(isDarkMode(), #212E48, #F1FAFF) !default; // Custom variable
$primary-inverse:  									$white !default;  // Custom variable

// Secondary colors
$secondary-active:  								$secondary-300;
$secondary-light:  									$gray-100 !default; // Custom variable
$secondary-inverse:									$gray-800 !default; // Custom variable

// Light colors
$light:     									      if(isDarkMode(),$gray-300, $gray-100) !default; // Bootstrap variable
$light-active:  								    if(isDarkMode(),$gray-400, $gray-200) !default; // Custom variable
$light-inverse:									    if(isDarkMode(),$gray-800, $gray-600) !default; // Custom variable
$lighten:                           rgba($light, 0.5); // Custom variable

// Success colors
$success-active:    								$success-300;
$success-light:    									if(isDarkMode(), #1C3833, #C9F7F5) !default; // Custom variable
$success-inverse:  									$white !default; // Custom variable

// Inco colors
$info-active:   		 						   	$info-300;
$info-light:   		 							   	if(isDarkMode(), #2F264F, #EEE5FF) !default; // Custom variable
$info-inverse: 		 							   	$white !default; // Custom variable

// Warning colors
$warning-active:    								$primary-300;
$warning-light:    									if(isDarkMode(), #392F28, #FFF4DE) !default; // Custom variable
$warning-inverse:  									$white !default; // Custom variable

// Danger colors
$danger-active:     								$danger-300;
$danger-light:     									if(isDarkMode(), #3A2434, #FFE2E5) !default; // Custom variable
$danger-inverse:   									$white !default; // Custom variable

// White colors
$white-active:    									$gray-100 !default; // Custom variable
$white-inverse:    									$gray-600 !default; // Custom variable


// Theme colors
$theme-colors: (
    "white":      $white,  // custom color type
    "light":      $light,
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "success-100":$success-100,
    "success-200":$success-200,
    "success-300":$success-300,
    "info":       $info,
    "info-400":   $info-400,
    "info-100":   $info-100,
    "info-500":   $info-500,
    "warning":    $primary,
    "warning-100":$primary-100,
    "warning-400":$primary-400,
    "danger":     $danger,
    "danger-100": $danger-100,
    "danger-200": $danger-200,
    "danger-300": $danger-300,
    "dark":       $dark
) !default;

// Hover colors
$theme-active-colors: (
    "white":      $white-active,   // custom color type
    "primary":    $primary-active,
    "secondary":  $secondary-active,
    "light":      $light-active,
    "success":    $success-active,
    "info":       $info-active,
    "warning":    $warning-active,
    "danger":     $danger-active,
    "dark":       $dark-active
) !default;  // Custom variable

// Inverse colors
$theme-inverse-colors: (
    "white":      $white-inverse,
    "primary":    $primary-inverse,
    "secondary":  $secondary-inverse,
    "light":      $light-inverse,
    "success":    $success-inverse,
    "info":       $info-inverse,
    "warning":    $warning-inverse,
    "danger":     $danger-inverse,
    "dark":       $dark-inverse
) !default;  // Custom variable

// Light colors
$theme-light-colors: (
    "primary":    $primary-light,
    "success":    $success-light,
    "info":       $info-light,
    "warning":    $warning-light,
    "danger":     $danger-light,
    "dark":       $dark-light
) !default;  // Custom variable

// Text colors
$theme-text-colors: (
    "white":      $white,
    "primary":    $primary,
    "secondary":  $secondary,
    "light":      $light,
    "success":    $success,
    "info":       $info,
    "warning":    $primary,
    "danger":     $danger,
    "dark":       $dark,
    "muted":      $text-muted,
    "gray-100":   $gray-100,
    "gray-200":   $gray-200,
    "gray-300":   $gray-300,
    "gray-400":   $gray-400,
    "gray-500":   $gray-500,
    "gray-600":   $gray-600,
    "gray-700":   $gray-700,
    "gray-800":   $gray-800,
    "gray-900":   $gray-900
) !default;  // Custom variable

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: 									true !default;
$enable-shadows:                  true !default;
$enable-negative-margins:         true !default;
$enable-smooth-scroll:            false !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 16px;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: (
    0: 0,
    1: ($spacer * .25),  	// 4px
    2: ($spacer * .5),   	// 8px;
    3: ($spacer * .75),  	// 12px
    4: ($spacer * 1),    	// 16px
    5: ($spacer * 1.25), 	// 20px
    6: ($spacer * 1.5),  	// 24px
    7: ($spacer * 1.75), 	// 28px
    8: ($spacer * 2),    	// 32px
    9: ($spacer * 2.25), 	// 36px
    10: ($spacer * 2.5), 	// 40px
    11: ($spacer * 2.75),	// 44px
    12: ($spacer * 3),   	// 48px
    13: ($spacer * 3.25),	// 52px
    14: ($spacer * 3.5), 	// 56px
    15: ($spacer * 3.75), // 60px
    16: ($spacer * 4), 		// 64px
    17: ($spacer * 4.25), // 68px
    18: ($spacer * 4.5), 	// 72px
    19: ($spacer * 4.75), // 76px
    20: ($spacer * 5), 		// 80px
);


// Position
//
// Define the edge positioning anchors of the position utilities.

$position-values: (
    0: 0,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
) !default;


// Body
//
// Settings for the `<body>` element.
$body-bg:                                           if(isDarkMode(), #1E1E2D, $gray-100) !default;
$body-color:                                        $black !default;
$body-text-align:                                   null !default;


// Links
//
// Style anchor elements.
$link-color:                                        $utility-link;
$link-decoration:                                   none !default;
$link-hover-color:                                  $info-500;
$link-hover-decoration:                             none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage:           0% !default;



// Paragraphs
//
// Style p element.
$paragraph-margin-bottom:                           8px;



// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
) !default;
// scss-docs-end grid-breakpoints



// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
) !default;
// scss-docs-end container-max-widths



// Components
//
// Define common padding and border radius sizes and more.

$border-width:                            1px !default;
$border-active-width:                     2px;
$border-color:                            $neutral-200;
$border-dashed-color:                     $gray-300 !default;  // Custom variable

$border-widths: (
    0: 0,
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px
) !default;

$rounded-pill:                            800px !default;

$component-active-color:                  $primary-inverse !default;
$component-active-bg:                     $primary !default;
$component-hover-bg:                      $primary-light !default;
$component-hover-color:                   $primary !default;

$caret-width:                             4px !default;
$caret-vertical-align:                    ($caret-width * .85) !default;
$caret-spacing:                           ($caret-width * .85) !default;

$transition-base:                         all .25s ease !default;
$transition-fade:                         opacity .2s linear !default;
$transition-collapse:                     height .3s ease !default;
$transition-link:                         all .25s ease .15s !default;
$transition-input:                        color .25s ease, background-color .25s ease !default;


// Components
//
// Define common padding and border radius sizes and more.

$component-active-color:                  $primary-inverse !default;
$component-active-bg:                     $primary !default;

// Custom hover and active background colors
$hover-bg:                                rgba($gray-100, 0.8) !default; // Custom variable(to remove)
$active-bg:                               $hover-bg !default; // Custom variable(to remove)


// Border Radiues
$border-radius-sm:            						4px;
$border-radius:               						8px;
$border-radius-md:               					12px;
$border-radius-lg:            						16px;
$border-radius-xl:                        24px;

// Box shadow
$box-shadow-xs:                           0 16px 24px -8px rgba($black, .4);
$box-shadow-sm:               						0 2px 4px 0 rgba($black, .08);
$box-shadow:                  						0 -4px 16px -8px rgba($black, .08),
0 4px 8px 0 rgba($black, .16);
$box-shadow-lg:               						0 56px 40px -40px rgba($black, .24),
0 0 24px 0 rgba($black, .08);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                                      12 !default;
$grid-gutter-width:                                 16px;
$grid-row-columns:                                  6 !default;

$gutters: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: ($spacer * .75),
    4: ($spacer * 1),
    5: ($spacer * 1.25),
    6: ($spacer * 1.5),
    7: ($spacer * 1.75),
    8: ($spacer * 2),
    9: ($spacer * 2.25),
    10: ($spacer * 2.5),
) !default;

// Container padding

$container-padding-x:                     24px;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Font family
$font-family-sans-serif: "Beeline Sans", Helvetica, "sans-serif" !default;

$font-size-base: 0.9375rem; // 15px
$font-size-lg: 1.125rem; // 18px
$font-size-sm: 0.8125rem; // 13px
$font-size-body: $font-size-base;

$font-weight-lighter:                     lighter !default;
$font-weight-light:                       300 !default;
$font-weight-normal:                      400 !default;
$font-weight-medium:                      500;
$font-weight-bold:                        700 !default;
$font-weight-bolder:                      700 !default;
$font-weight-boldest:                     800 !default;

$font-weight-base:                        $font-weight-normal !default;
$font-weight-regular:                     $font-weight-normal !default;

// Line heights
$line-height-base:                        1.3333;
$line-height-sm:                          1.2307;
$line-height-lg:                          1.3333;
$line-height-xl:                          1.1111 !default;
$line-height-xxl:                         1 !default;

// Heading sizes
$h1-font-size:                            3rem; // 48px
$h2-font-size:                            2.25rem; // 36px
$h3-font-size:                            1.75rem; // 28px
$h4-font-size:                            1.375rem; // 22px
$h5-font-size:                            1.125rem; // 18px
$h6-font-size:                            1rem; // 16px

// Font Sizes
$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,

    7: ($font-size-base * 0.95),
    8: ($font-size-base * 0.85),
    9: ($font-size-base * 0.75),
    10: ($font-size-base * 0.5),

    base: $font-size-base,
    sm: $font-size-sm,
    lg: $font-size-lg,
    fluid: 100%, // 100%

    2x: ($font-size-base * 2),
    2qx: ($font-size-base * 2.25),
    2hx: ($font-size-base * 2.5),
    2tx: ($font-size-base * 2.75),

    3x: ($font-size-base * 3),
    3qx: ($font-size-base * 3.25),
    3hx: ($font-size-base * 3.5),
    3tx: ($font-size-base * 3.75),

    4x: ($font-size-base * 4),
    4qx: ($font-size-base * 4.25),
    4hx: ($font-size-base * 4.5),
    4tx: ($font-size-base * 4.75),

    5x: ($font-size-base * 5),
    5qx: ($font-size-base * 5.25),
    5hx: ($font-size-base * 5.5),
    5tx: ($font-size-base * 5.75)
) !default;

$headings-margin-bottom:                            ($spacer * 0.5) !default;
$headings-font-family:                              null !default;
$headings-font-style:                               null !default;
$headings-font-weight:                              $font-weight-bolder !default;
$headings-line-height:                              1.1 !default;
$headings-color:                                    $black !default;

$display-font-sizes: (
    1: 80px,
    2: 72px,
    3: 64px,
    4: 56px,
    5: 48px,
    6: 40px
) !default;

$display-font-weight:                               $font-weight-bolder !default;
$display-line-height:                               $headings-line-height !default;



// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:                               10px;
$input-btn-padding-x:                               16px;
$input-btn-font-family:                             null !default;
$input-btn-font-size:                               $font-size-base;
$input-btn-line-height:                             $line-height-base !default;

$input-btn-focus-width:                             4px !default;
$input-btn-focus-color-opacity:                     .25 !default;
$input-btn-focus-color:                             rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-box-shadow:                        null !default;

$input-color:                                       $black;
$input-placeholder-color:                           $neutral-300;
$input-plaintext-color:                             $black;

$input-btn-padding-y-sm:                            6px;
$input-btn-padding-x-sm:                            8px;
$input-btn-font-size-sm:                            $font-size-sm !default;

$input-btn-padding-y-lg:                            12px !default;
$input-btn-padding-x-lg:                            20px !default;
$input-btn-font-size-lg:                            ($font-size-base * 1.15) !default;

$input-btn-border-width:                            $border-width !default;



// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:                                     $input-btn-padding-y !default;
$btn-padding-x:                                     $input-btn-padding-x !default;
$btn-font-family:                                   $input-btn-font-family !default;
$btn-font-size:                                     $input-btn-font-size !default;
$btn-font-weight:                                   500;
$btn-line-height:                                   $input-btn-line-height !default;
$btn-white-space:                                   null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:                                  $input-btn-padding-y-sm !default;
$btn-padding-x-sm:                                  $input-btn-padding-x-sm !default;
$btn-font-size-sm:                                  $input-btn-font-size-sm !default;

$btn-padding-y-lg:                                  $input-btn-padding-y-lg !default;
$btn-padding-x-lg:                                  $input-btn-padding-x-lg !default;
$btn-font-size-lg:                                  $input-btn-font-size-lg !default;

$btn-border-width:                                  $input-btn-border-width !default;

$btn-box-shadow:                                    null !default;
$btn-focus-width:                                   $input-btn-focus-width !default;
$btn-focus-box-shadow:                              null !default;
$btn-disabled-opacity:                              1 !default;
$btn-active-box-shadow:                             null !default;

$btn-link-color:                                    $link-color !default;
$btn-link-hover-color:                              $link-hover-color !default;
$btn-link-disabled-color:                           $neutral-300;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:                                 $border-radius !default;
$btn-border-radius-sm:                              6px;
$btn-border-radius-lg:                              $border-radius !default;

$btn-transition:                                    color .15s ease-in-out,
background-color .15s ease-in-out,
border-color .15s ease-in-out,
box-shadow .15s ease-in-out !default;

$btn-extended-variants:                             true !default; // Custom variable


// Forms
$form-label-margin-bottom:                          4px;
$form-label-font-size:                              $font-size-sm;
$form-label-font-weight:                            500 !default;
$form-label-color:                                  $black;

$form-text-margin-top:                              0;
$form-text-font-size:                               $font-size-base !default;
$form-text-color:                                   $black !default;

$input-padding-y:                                   10px;
$input-padding-x:                                   12px;
$input-font-family:                                 $input-btn-font-family !default;
$input-font-size:                                   $input-btn-font-size !default;
$input-font-weight:                                 400;
$input-line-height:                                 $input-btn-line-height !default;

$input-solid-bg:                                    rgba(#232325, .04);
$input-solid-bg-focus:                              $input-solid-bg;
$input-solid-placeholder-color:                     $neutral-300;
$input-solid-color:                               	$black;

$input-bg:                                          $input-solid-bg;
$input-disabled-bg:                                 $secondary-100;
$input-readonly-bg:                                 $input-bg !default;  // Custom variable
$input-disabled-border-color:                       null !default;

$input-color:                                       $body-color !default;
$input-border-color:                                null;
$input-border-width:                                0;

$input-border-radius:                               $btn-border-radius !default;
$input-border-radius-sm:                            $btn-border-radius-sm !default;
$input-border-radius-lg:                            $btn-border-radius-lg !default;

$input-focus-border-color:                          $primary-400 !default;
$input-focus-color:                                 $input-color !default;

$form-check-input-width:                            20px;
$form-check-input-width-sm:                         20px;
$form-check-input-width-lg:                         20px;
$form-check-input-bg-size:                          90% 90% !default; // Custom variable
$form-check-min-height:                             ($font-size-base * $line-height-base) !default;
$form-check-padding-start:                          ($form-check-input-width + 8px);
$form-check-padding-left:                           ($form-check-input-width + 8px);
$form-check-margin-bottom:                          0;
$form-check-label-color:                            $black;
$form-check-label-cursor:                           pointer !default;
$form-check-transition:                             background-color .2s ease-in-out,
background-position .2s ease-in-out,
border-color .2s ease-in-out,
box-shadow .2s ease-in-out !default;

$form-check-input-active-filter:                    null;

$form-check-input-bg:                               rgba(#232325, .08);
$form-check-input-bg-solid:                         $form-check-input-bg;
$form-check-input-border:                           none;
$form-check-input-border-radius:                    $border-radius-sm;
$form-check-radio-border-radius:                    50% !default;
$form-check-input-focus-border:                     $input-focus-border-color !default;
$form-check-input-focus-box-shadow:                 none !default;

$form-check-input-checked-color:                    $black;
$form-check-input-checked-bg-color:                 $primary-300;
$form-check-input-checked-border-color:             $primary-300;
$form-check-input-checked-bg-image:                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'><path d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='#{$form-check-input-checked-color}'/></svg>") !default;
$form-check-radio-checked-bg-image:                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color:              $black;
$form-check-input-indeterminate-bg-color:           $input-bg;
$form-check-input-indeterminate-border-color:       null;
$form-check-input-indeterminate-bg-image:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity:                 1;
$form-check-label-disabled-opacity:                 $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity:             $btn-disabled-opacity !default;

$form-switch-color:                                 rgba(0, 0, 0, .25) !default;
$form-switch-color-solid:                           if(isDarkMode(), $gray-500, $white) !default;
$form-switch-width:                                 52px;
$form-switch-height:                                36px;
$form-switch-width-sm:                              40px;
$form-switch-height-sm:                             24px;
$form-switch-width-lg:                              60px;
$form-switch-height-lg:                             44px;

$form-switch-padding-start:                         ($form-switch-width + 8px) !default;
$form-switch-padding-left:                          ($form-switch-width + 8px) !default;
$form-switch-bg-image:                              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-bg-image-solid:                        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-solid}'/></svg>") !default;
$form-switch-border-radius:                         $form-switch-width !default;

$form-switch-focus-color:                           $input-focus-border-color !default;
$form-switch-focus-bg-image:                        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color:                         $component-active-color !default;
$form-switch-checked-bg-image:                      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position:                   right center !default;

$form-check-inline-margin-right:                    16px;

$input-group-addon-bg:                              $gray-100 !default;
$input-group-addon-color:                           $gray-800 !default;

$form-select-indicator-color:                       $neutral-400 !default;
$form-select-border-radius:                         $border-radius !default;

// Navs
$nav-tabs-border-color:                             $border-color !default;
$nav-tabs-border-width:                             $border-width !default;



// Card
$card-bg: $white;
$card-box-shadow: $box-shadow-sm;
$card-border-color: null;
$card-border-style: null;
$card-border-dashed-color: null;
$card-cap-bg: $white !default;
$card-py: 16px;
$card-px: 24px;
$card-border-radius: $border-radius-lg;

$card-header-py:                                    16px;
$card-header-height: 								                56px !default;

$card-border-enabled:                               false !default; // Custom variable

// Accordion
$accordion-color:                                   $white;
$accordion-padding-y:                               16px;
$accordion-padding-x:                               24px;
$accordion-border-color:                            $border-color !default;
$accordion-border-radius:                           $border-radius-lg !default;

$accordion-body-padding-y:                          $accordion-padding-y !default;
$accordion-body-padding-x:                          $accordion-padding-x !default;

$accordion-button-padding-y:                        $accordion-padding-y !default;
$accordion-button-padding-x:                        $accordion-padding-x !default;
$accordion-transition:                              $btn-transition, border-radius .25s ease !default;
$accordion-button-active-bg:                        $gray-100 !default;
$accordion-button-active-color:                     $primary !default;

$accordion-button-focus-border-color:               $border-color  !default;
$accordion-button-focus-box-shadow:                 none !default;

$accordion-icon-width:                              16px;
$accordion-icon-active-color:                       $accordion-button-active-color !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding:              					40px;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:       				8px;

$modal-dialog-margin:               				8px;
$modal-dialog-margin-y-sm-up:       				24px;

$modal-header-border-color:                 $border-color !default;
$modal-footer-border-color:                 $modal-header-border-color !default;
$modal-header-border-width:                 0;
$modal-footer-border-width:                 0;

$modal-content-color:               				null !default;
$modal-content-bg:                  				$white;
$modal-content-border-color:        				null !default;
$modal-content-border-width:        				0 !default;
$modal-content-border-radius:       				$border-radius-xl;
$modal-content-inner-border-radius: 				$border-radius-xl;

$modal-content-box-shadow:       				    $box-shadow-lg;
$modal-content-box-shadow-xs:       				$box-shadow-lg;
$modal-content-box-shadow-sm-up:   					$box-shadow-lg;

$modal-backdrop-bg:                		 			$neutral-500;
$modal-backdrop-opacity:            				if(isDarkMode(), 0.4, 0.4);

$modal-xl:                         		 			1140px;
$modal-lg:                          				960px;
$modal-md:                          				640px;
$modal-sm:                          				400px;

$modal-fade-transform:                      scale(.7);
$modal-transition:                          transform .2s ease-in-out;


// Alerts
// Define alert colors, border radius, and padding.
$alert-color-scale:                                 if(isDarkMode(), 60%, 40%) !default;
$alert-border-scale:                                if(isDarkMode(), -60%, -70%) !default;
$alert-bg-scale:                                    if(isDarkMode(), -60%, -80%) !default;



// Dropdowns
// Dropdown menu container and contents.
$dropdown-box-shadow:								                $box-shadow;
$dropdown-border-radius:                            $border-radius !default;
$dropdown-border-width:                             0 !default;
$dropdown-bg:                                       $white;



// Pagination

$pagination-item-height:                            32px;
$pagination-item-bg:                                transparent;
$pagination-item-space:                             4px;
$pagination-item-space-tablet-and-mobile:           4px;
$pagination-font-weight:                            $font-weight-regular;
$pagination-font-size:                              $font-size-body;
$pagination-icon-font-size:                         24px;
$pagination-icon-height:                            $pagination-item-height;

$pagination-padding-y:                              6px;
$pagination-padding-x:                              8px;
$pagination-padding-y-sm:                           6px;
$pagination-padding-x-sm:                           8px;
$pagination-padding-y-lg:                           6px;
$pagination-padding-x-lg:                           8px;

$pagination-color:                                  $black;
$pagination-bg:                                     transparent !default;
$pagination-border-width:                           0 !default;
$pagination-border-radius:                          $btn-border-radius !default;
$pagination-margin-left:                            0 !default;
$pagination-border-color:                           transparent !default;

$pagination-focus-color:                            $black;
$pagination-focus-bg:                               transparent;
$pagination-focus-box-shadow:                       none !default;
$pagination-focus-outline:                          2px;

$pagination-hover-color:                            $black;
$pagination-hover-bg:                               transparent;
$pagination-hover-border-color:                     $neutral-300;

$pagination-active-color:                           $black;
$pagination-active-bg:                              $primary-300;
$pagination-active-border-color:                    transparent !default;

$pagination-disabled-color:                         $neutral-300;
$pagination-disabled-bg:                            $white;
$pagination-disabled-border-color:                  transparent !default;

$pagination-transition:                             color .15s ease-in-out,
background-color .15s ease-in-out,
border-color .15s ease-in-out,
box-shadow .15s ease-in-out !default;

// Popovers
$popover-bg:                                        $white;
$popover-font-size:                 				        $font-size-base !default;
$popover-border-color:              				        transparent;
$popover-border-radius:             				        $border-radius !default;
$popover-box-shadow:                				        $box-shadow !default;
$popover-header-bg:                 				        $popover-bg !default;
$popover-header-color:              				        $black;
$popover-header-font-weight:           				      500 !default;  // Custom variable
$popover-header-font-size:             				      15px;  // Custom variable
$popover-header-padding-y:          				        16px;
$popover-header-padding-x:          				        16px;
$popover-header-border-color:                       $neutral-300;
$popover-body-color:                			         	$black;
$popover-body-padding-y:                            16px;
$popover-body-padding-x:                            16px;

$popover-dissmis-btn-height:                        24px;
$popover-dissmis-btn-top:                           8px;
$popover-dissmis-btn-end:                           8px;

// Tooltips
$tooltip-font-size:                 				        $font-size-sm !default;
$tooltip-max-width:                 				        360px !default;
$tooltip-color:                     				        $white;
$tooltip-bg:                        				        rgba($neutral-700, .9);
$tooltip-border-radius:             				        $border-radius;
$tooltip-opacity:                   				        1 !default;
$tooltip-box-shadow:                				        $box-shadow-xs;
$tooltip-padding-y:                 				        6px;
$tooltip-padding-x:                 				        8px;

// Toasts
$toast-max-width:                                   360px;
$toast-border-width:                                0;
$toast-border-radius:                               12px;

// Badges
$badge-font-size:                                   $font-size-body;
$badge-font-size-sm:                                $font-size-sm;
$badge-font-size-lg:                                $font-size-body;
$badge-font-weight:                                 $font-weight-medium;
$badge-color:                                       $neutral-600 !default;
$badge-padding-y:                                   6px;
$badge-padding-x:                                   8px;
$badge-border-radius:                               $border-radius !default;
$badge-size:                                        32px;
$badge-size-sm:                                     24px;
$badge-size-lg:                                     32px;



// Code
$code-bg:                                           if(isDarkMode(), $gray-200, #F1F3F8) !default; // Custom variable
$code-box-shadow:                                   0px 3px 9px rgba(0, 0, 0, 0.08) !default; // Custom variable
$code-padding:                                      4px 6px !default; // Custom variable
$code-margin:                                       0 8px !default; // Custom variable
$code-font-weight:                                  400 !default; // Custom variable
$code-border-radius:                                4px !default;  // Custom variable
$code-color:                                        #b93993 !default;  // Custom variable
$code-space:                                        4px !default;  // Custom variable



// Carousel
$carousel-custom-indicator-transition-speed:        0.3s !default;
$carousel-custom-indicator-default-bg-color:        $gray-200 !default;
$carousel-custom-indicator-active-bg-color:         $gray-400 !default;

$carousel-custom-dots-indicator-default-size:       9px !default;
$carousel-custom-dots-indicator-active-size:        13px !default;

$carousel-custom-bullet-indicator-default-size:     6px !default;
$carousel-custom-bullet-indicator-active-width:     16px !default;
$carousel-custom-bullet-indicator-default-bg-color: $gray-400 !default;
$carousel-custom-bullet-indicator-active-bg-color:  $gray-600 !default;

// Spinners

$spinner-border-width:                              3px !default;
$spinner-animation-speed:                           .65s !default;
$spinner-border-width-sm:                           2px !default;



// Breadcrumbs
$breadcrumb-divider-color:                          $neutral-400;
$breadcrumb-active-color:                           $black;
$breadcrumb-item-padding-x:                         12px;



// Progress bars
$progress-height:                                   16px !default;
$progress-font-size:                                ($font-size-base * .75) !default;
$progress-bg:                                       $gray-100 !default;
$progress-border-radius:                            6px !default;
$progress-box-shadow:                               none !default;



// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding-y:                              10px;
$table-cell-padding-x:                              8px;
$table-cell-padding-y-sm:                           10px;
$table-cell-padding-x-sm:                           8px;

$table-color:                                       $black;
$table-bg:                                          transparent !default;
$table-striped-color:                               $table-color !default;
$table-striped-bg:                                  rgba($gray-100, 0.75) !default;

$table-active-color:                                $table-color !default;
$table-active-bg:                                   $secondary-100 !default;

$table-hover-color:                                 $table-color !default;
$table-hover-bg:                                    $secondary-100 !default;

$table-border-factor:                               .1 !default;
$table-border-width:                                $border-width !default;
$table-border-color:                                $border-color !default;



// Opacity
$opacity-values: (
    0: 0,
    5: .05,
    10: .1,
    15: .15,
    20: .20,
    25: .25,
    50: .5,
    75: .75,
    100: 1,
) !default;

// Zindex
$zindex-values: (
    n1: -1,
    n2: -2,
    0: 0,
    1: 1,
    2: 2,
    3: 3
) !default;

// Letter spacing
$letter-spacing-values: (
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0
) !default;

// Custom height and width sizes
$custom-sizes: (
    unset: unset,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
    1px: 1px,
    2px: 2px,
    3px: 3px,
    4px: 4px,
    5px: 5px,
    6px: 6px,
    7px: 7px,
    8px: 8px,
    9px: 9px,
    10px: 10px,
    15px: 15px,
    20px: 20px,
    25px: 25px,
    30px: 30px,
    35px: 35px,
    40px: 40px,
    45px: 45px,
    50px: 50px,
    55px: 55px,
    60px: 60px,
    65px: 65px,
    70px: 70px,
    75px: 75px,
    80px: 80px,
    85px: 85px,
    90px: 90px,
    95px: 95px,
    100px: 100px,
    125px: 125px,
    150px: 150px,
    175px: 175px,
    200px: 200px,
    225px: 225px,
    250px: 250px,
    275px: 275px,
    300px: 300px,
    325px: 325px,
    350px: 350px,
    375px: 375px,
    400px: 400px,
    425px: 425px,
    450px: 450px,
    475px: 475px,
    500px: 500px,
    550px: 550px,
    600px: 600px,
    650px: 650px,
    700px: 700px,
    750px: 750px,
    800px: 800px,
    850px: 850px,
    900px: 900px,
    950px: 950px,
    1000px: 1000px
) !default;



// Symbol Sizes
$symbol-sizes: (
    20px: 20px,
    25px: 25px,
    30px: 30px,
    35px: 35px,
    40px: 40px,
    45px: 45px,
    default: 50px,
    50px: 50px,
    55px: 55px,
    60px: 60px,
    65px: 65px,
    70px: 70px,
    75px: 75px,
    100px:100px,
    125px:125px,
    150px:150px,
    160px:160px,
    175px:175px,
    200px:200px
) !default;



// Bullet
$bullet: (
    bg-color: $gray-400,
    dot-size: 4px,
    bar-height: 4px,
    bar-width: 8px,
    bar-border-radius: 6px,
    line-height: 1,
    line-width: 5px,
) !default;



// Scrolltop
$scrolltop: (
    opacity: (
        default: 0,
        on: 0.3,
        hover: 1
    ),
    bottom: (
        desktop: 40px,
        tablet-and-mobile: 30px
    ),
    right: (
        desktop: 20px,
        tablet-and-mobile: 15px
    ),
    size: (
        desktop: 36px,
        tablet-and-mobile: 30px
    ),
    bg-color: (
        default: $primary,
        hover: $primary
    ),
    icon-color: (
        default: $white,
        hover: $white
    )
) !default;



// Sticky Toolbar
$sticky-toolbar: (
    z-index: 95
) !default;



// Drawer
$drawer: (
    z-index: 110, // Drawer's z-index
    box-shadow: none, // Drawer's box shadow
    bg-color: transparent, // Drawer's background color
    transition-speed: 0.3s,
    overlay-bg-color: if(isDarkMode(), rgba($black, 0.4), rgba($black, 0.2)), // Offcanvas overlay background color
    overlay-animation-speed: 0.3s
) !default;



// Menu
$menu: (
    dropdown: (
        box-shadow: $dropdown-box-shadow,
        z-index: 105,
        background-color: $white,
        border-radius: $border-radius,
        animation: true,
        animation-speed: 0.3s,
        animation-move-offset: 12px
    ),

    accordion: (
        indention: 16px,
        arrow-transition: transform 0.3s ease
    ),

    item: (
        self: (
            padding-y: 12px,
            padding-x: 16px
        )
    ),

    link: (
        self: (
            padding-y: 8px,
            padding-x: 16px,
            bg-color: (
                hover: $hover-bg,
                show: $hover-bg,
                here: $hover-bg,
                active: $active-bg
            )
        ),

        icon: (
            width: 24px,
            space: 12px
        ),

        bullet: (
            width: 24px,
            space: 4px
        ),

        badge: (
            space: 8px
        ),

        arrow: (
            height: 16px,
            width: 16px,
            space: 4px
        )
    )
) !default;



// Feedback
$feedback: (
    popup: (
        z-index: 1000,
        box-shadow: $dropdown-box-shadow,
        background-color: $body-bg,
        border-radius: $border-radius,
        transition-speed: 0.6s,
        padding: 16px 20px
    )
) !default;



// Custom scrollbar
$scrollbar: (
    space: 6px,
    width: 12px,
    height: 12px,
    min-size: 32px,
    color: rgba(black, .4),
) !default;



// Overlay
$overlay: (
    bg: rgba($black, 0.05)
) !default;



// BlockUI
$blockui: (
    overlay-bg: rgba($black, 0.05)
) !default;



// Rating
$rating: (
    color: (
        default: $neutral-300,
        active: $primary-300
    )
) !default;



// Sticky
$fixed: (
    z-index: 101
) !default;



// Container
$container: (
    padding: (
        default: 24px,
        lg: 40px
    )
) !default;


// Social Network Colors Used With Boostrap Buttons(see: https://brandcolors.net)
$social-colors: (
    facebook: (
        base: #3b5998,
        inverse: #ffffff,
        light: rgba(#3b5998, 0.1),
        active: darken(#3b5998, 7.5%)
    ),
    google: (
        base: #dd4b39,
        inverse: #ffffff,
        light: rgba(#dd4b39, 0.1),
        active: darken(#dd4b39, 7.5%)
    ),
    twitter: (
        base: #1da1f2,
        inverse: #ffffff,
        light: rgba(#1da1f2, 0.1),
        active: darken(#1da1f2, 7.5%)
    ),
    instagram: (
        base: #e1306c,
        inverse: #ffffff,
        light: rgba(#e1306c, 0.1),
        active: darken(#e1306c, 7.5%)
    ),
    youtube: (
        base: #ff0000,
        inverse: #ffffff,
        light: rgba(#ff0000, 0.1),
        active: darken(#ff0000, 7.5%)
    ),
    linkedin: (
        base: #0077b5,
        inverse: #ffffff,
        light: rgba(#0077b5, 0.1),
        active: darken(#0077b5, 7.5%)
    )
) !default; // Custom variable

// @formatter:on
