//
// Tagify Mixins
//

@mixin tagify-size($input-padding-y, $input-height-border, $tag-font-size, $tag-border-radius, $tag-padding-y, $tag-padding-x, $tag-margin-y, $tag-margin-x, $remove-size, $remove-margin-x) {

  $calculated-input-padding-y: calc((#{$input-padding-y * 2} + #{$input-height-border} - #{2 * $tag-padding-y + 2 * $tag-margin-y}) / 2);
  $calculated-tag-input-margin-y: calc(#{$input-padding-y} - (#{$input-padding-y * 2} + #{$input-height-border} - #{2 * $tag-padding-y + 2 * $tag-margin-y}) / 2);

  padding-top: $calculated-input-padding-y;
  padding-bottom: $calculated-input-padding-y;

  .tagify__tag {
    border-radius: $tag-border-radius;
    padding: $tag-padding-y $tag-padding-x;
    margin: $tag-margin-y $tag-margin-x $tag-margin-y 0;
    font-weight: inherit;

    div {
      padding: 0 $tag-padding-x;
      font-size: $tag-font-size;
      font-weight: inherit;
    }

    .tagify__tag__removeBtn {
      width: $remove-size;
      height: $remove-size;
      margin: 0 $remove-margin-x 0 0;
    }
  }

  .tagify__input {
    padding: 0;
    margin-top: $calculated-tag-input-margin-y;
    margin-bottom: $calculated-tag-input-margin-y;
  }
}