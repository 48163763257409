//
// Horizontal Stepper
//
.stepper-h {
  box-shadow: inset 0 -1px 0 $neutral-300;
  flex-shrink: 0;
  flex-wrap: nowrap;
  overflow-x: auto;
  position: relative;

  &::before,
  &::after {
    content: '';
    background-color: $page-bg;
    bottom: 0;
    display: block;
    height: 1px;
    position: absolute;
    width: 24px;
    z-index: 1;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &__item {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    gap: 12px;
    padding: 16px;
    position: relative;
    z-index: 2;

    &_current {
      box-shadow: inset 0 -2px 0 $black;

      .stepper-h__item-number {
        background-color: $primary-300;
        box-shadow: none;
      }

      .stepper-h__item-number,
      .stepper-h__item-label {
        color: $black;
      }
    }
  }

  &__item-number {
    $size: 24px;
    box-shadow: inset 0 0 0 2px $neutral-300;
    border-radius: 50%;
    height: $size;
    padding: 2px 4px;
    text-align: center;
    width: $size;
  }

  &__item-number,
  &__item-label {
    @include text-body-regular;
    color: $neutral-500;
  }

  &__item-icon {
    $size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    height: $size;
    width: $size;

    &_success {
      @include svg-bg-icon(success);
    }

    &_error {
      @include svg-bg-icon(error);
    }

    & + .stepper-h__item-label {
      color: $black;
    }
  }
}
