//
// Code
//

code:not([class*="language-"]) {
  font-weight: $code-font-weight;
  color: $code-color;
  line-height: inherit;
  font-size: inherit;
  background-color: $code-bg;
  padding: $code-padding;
  margin: $code-margin;
  box-shadow: $code-box-shadow;
  @include border-radius($code-border-radius);
}
