/**
  margin-top (rem)
  Чтобы не перекрывать уже дважды перекрытые стили bootstrap, вводим свои отступы в нужных единицах измерения
 */
.mt-r-1 {
  margin-top: 1rem;
}

.mt-r-2 {
  margin-top: 2rem;
}

.mt-r-3 {
  margin-top: 3rem;
}

.mt-r-4 {
  margin-top: 4rem;
}

.mt-r-5 {
  margin-top: 5rem;
}

/**
  margin-bottom (rem)
 */
.mb-r-1 {
  margin-bottom: 1rem;
}

.mb-r-1-5 {
  margin-bottom: 1.5rem;
}

.mb-r-2 {
  margin-bottom: 2rem;
}

.mb-r-3 {
  margin-bottom: 3rem;
}

.mb-r-4 {
  margin-bottom: 4rem;
}

.mb-r-5 {
  margin-bottom: 5rem;
}
