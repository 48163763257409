@font-face {
  font-family: "Beeline Sans";
  font-weight: 400; // regular
  src: url("fonts/beeline-sans-regular.ttf") format("truetype"),
  url("fonts/beeline-sans-regular.woff") format("woff"),
  url("fonts/beeline-sans-regular.woff2") format("woff2"),
  url("fonts/beeline-sans-regular.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Beeline Sans";
  font-weight: 500; // medium
  src: url("fonts/beeline-sans-medium.ttf") format("truetype"),
  url("fonts/beeline-sans-medium.woff") format("woff"),
  url("fonts/beeline-sans-medium.woff2") format("woff2"),
  url("fonts/beeline-sans-medium.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Beeline Sans";
  font-weight: 700; // bold
  src: url("fonts/beeline-sans-bold.ttf") format("truetype"),
  url("fonts/beeline-sans-bold.woff") format("woff"),
  url("fonts/beeline-sans-bold.woff2") format("woff2"),
  url("fonts/beeline-sans-bold.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Beeline Sans";
  font-weight: 800; // black
  src: url("fonts/beeline-sans-black.ttf") format("truetype"),
  url("fonts/beeline-sans-black.woff") format("woff"),
  url("fonts/beeline-sans-black.woff2") format("woff2"),
  url("fonts/beeline-sans-black.eot") format("embedded-opentype");
}
